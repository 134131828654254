import { IReferralsState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";

export type ReferralsAction = ActionType<typeof actions>;

const initialState: IReferralsState = {
  data: [],
  ui: {
    pending: false,
    createReferralPending: false,
  },
};

const reducer = createReducer(initialState)
  .handleAction(actions.getReferralsSuccess, (state, { payload }) => ({
    ...state,
    data: payload,
    ui: {
      ...state.ui,
      pending: false,
    },
  }))
  .handleAction(actions.getReferralsPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      pending: true,
    },
  }))
  .handleAction(actions.createReferralPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      createReferralPending: true,
    },
  }))
  .handleAction(actions.createReferralSuccess, (state, { payload }) => ({
    ...state,
    ui: {
      ...state.ui,
      createReferralPending: false,
    },
    data: [...state.data, payload],
  }))
  .handleAction(actions.createReferralError, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      createReferralPending: false,
    },
  }));

export { reducer as referralsReducer };
