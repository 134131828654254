import { createStandardAction } from "typesafe-actions";
import { APIRequestConfig, IPaginated } from "../../types";
import {
  CompetitionAnalysisRequestPayload,
  CompetitionAnalysisResponse,
  CreateSenderResponse,
  IGmbLocation,
  ILocation,
  IPublicLocation,
  SendPlaceIdRequestPayload,
} from "./types";
import { requestActionCreator } from "../../actionCreators";
import { CreateSenderRequestPayload } from "./types";

export const getLocationsRequest = (page: number) => {
  return requestActionCreator("@locations/getLocations")({
    request: {
      url: "/locations",
      method: "GET",
      params: { page: `${page}`, page_size: "1000" },
    },
  });
};

export const getLocationsPending = createStandardAction(
  "@locations/getLocationsPending"
)();
export const getLocationsSuccess = createStandardAction(
  "@locations/getLocationsSuccess"
)<IPaginated<ILocation>, { originalRequest: APIRequestConfig }>();

export const getLocationSummariesRequest = () => {
  return requestActionCreator("@locations/getLocationSummaries")({
    request: {
      url: "/locations",
      method: "GET",
      params: { page_size: `1000` },
    },
  });
};

export const getLocationSummariesPending = createStandardAction(
  "@locations/getLocationSummariesPending"
)();
export const getLocationSummariesSuccess = createStandardAction(
  "@locations/getLocationSummariesSuccess"
)<IPaginated<ILocation>, { originalRequest: APIRequestConfig }>();

export const getLocationsError = createStandardAction(
  "@locations/getLocationsError"
)();

export const openAddLocationModal = createStandardAction(
  "@locations/openAddLocationModal"
)();
export const closeAddLocationModal = createStandardAction(
  "@locations/closeAddLocationModal"
)();

export const openQrModal = createStandardAction("@locations/openQrModal")();
export const closeQrModal = createStandardAction("@locations/closeQrModal")();

export const createLocationRequest = (payload: {
  name: string;
  place_id: string;
  job_type: number;
}) => {
  return requestActionCreator("@locations/createLocation")({
    request: {
      url: "/locations",
      method: "POST",
      payload,
    },
  });
};

export const createLocationPending = createStandardAction(
  "@locations/createLocationPending"
)();
export const createLocationSuccess = createStandardAction(
  "@locations/createLocationSuccess"
)<ILocation, { originalRequest: APIRequestConfig }>();
export const createLocationError = createStandardAction(
  "@locations/createLocationError"
)();

export const getLocationRequest = (id: number) => {
  return requestActionCreator("@locations/getLocation")({
    request: {
      url: `/locations/${id}`,
      method: "GET",
    },
  });
};
export const getLocationPending = createStandardAction(
  "@locations/getLocationPending"
)();
export const getLocationSuccess = createStandardAction(
  "@locations/getLocationSuccess"
)<ILocation, { originalRequest: APIRequestConfig }>();
export const getLocationError = createStandardAction(
  "@locations/getLocationError"
)();

export const getGmbLocationsRequest = () => {
  return requestActionCreator("@locations/getGmbLocations")({
    request: {
      url: "/gmb/locations",
      method: "GET",
    },
  });
};

export const getGmbLocationsPending = createStandardAction(
  "@locations/getGmbLocationsPending"
)();
export const getGmbLocationsSuccess = createStandardAction(
  "@locations/getGmbLocationsSuccess"
)<IPaginated<IGmbLocation>, { originalRequest: APIRequestConfig }>();

export const getGmbLocationsError = createStandardAction(
  "@locations/getGmbLocationsError"
)();

export const openSetGmbLocationModal = createStandardAction(
  "@locations/openSetGmbLocationModal"
).map((payload: number) => ({ payload }));

export const closeSetGmbLocationModal = createStandardAction(
  "@locations/closeSetGmbLocationModal"
)();

export const selectGmbLocation = createStandardAction(
  "@locations/selectGmbLocation"
).map((payload: number) => ({ payload }));

export const patchGmbLocationRequest = (
  id: number,
  payload: Partial<IGmbLocation>
) => {
  return requestActionCreator("@locations/patchGmbLocation")({
    request: {
      url: `/gmb/locations/${id}`,
      method: "PATCH",
      payload,
    },
  });
};

export const patchGmbLocationPending = createStandardAction(
  "@locations/patchGmbLocationPending"
)();

export const patchGmbLocationSuccess = createStandardAction(
  "@locations/patchGmbLocationSuccess"
)<IGmbLocation, { originalRequest: APIRequestConfig }>();

export const openEditLocationModal = createStandardAction(
  "@locations/openEditLocationModal"
).map((payload: number) => ({ payload }));
export const closeEditLocationModal = createStandardAction(
  "@locations/closeEditLocationModal"
)();

export const patchLocationRequest = (
  id: number,
  payload: Partial<ILocation>
) => {
  return requestActionCreator("@locations/patchLocation")({
    request: {
      url: `/locations/${id}`,
      method: "PATCH",
      payload,
    },
  });
};

export const patchLocationPending = createStandardAction(
  "@locations/patchLocationPending"
)();
export const patchLocationError = createStandardAction(
  "@locations/patchLocationError"
)();
export const patchLocationSuccess = createStandardAction(
  "@locations/patchLocationSuccess"
)<ILocation, { originalRequest: APIRequestConfig }>();

export const openDeleteLocationModal = createStandardAction(
  "@locations/openDeleteLocationModal"
).map((payload: number) => ({ payload }));
export const closeDeleteLocationModal = createStandardAction(
  "@locations/closeDeleteLocationModal"
)();

export const deleteLocationRequest = (id: number) => {
  return requestActionCreator("@locations/deleteLocation")({
    request: {
      url: `/locations/${id}`,
      method: "DELETE",
    },
  });
};

export const deleteLocationPending = createStandardAction(
  "@locations/deleteLocationPending"
)();
export const deleteLocationSuccess = createStandardAction(
  "@locations/deleteLocationSuccess"
)<ILocation, { originalRequest: APIRequestConfig }>();

export const archiveLocationRequest = (id: number) => {
  return requestActionCreator("@locations/archiveLocation")({
    request: {
      url: `/locations/${id}`,
      method: "PATCH",
      payload: { archived: true },
    },
  });
};

export const archiveLocationPending = createStandardAction(
  "@locations/archiveLocationPending"
)();
export const archiveLocationSuccess = createStandardAction(
  "@locations/archiveLocationSuccess"
)<ILocation, { originalRequest: APIRequestConfig }>();

// Create sender
export const createSenderRequest = (payload: CreateSenderRequestPayload) => {
  return requestActionCreator("@locations/createSender")({
    request: {
      url: "/locations/create_sender",
      method: "POST",
      payload: {
        from_name: payload.fromName,
        from_email: payload.fromEmail,
        location_id: payload.locationId,
      },
    },
  });
};

export const createSenderPending = createStandardAction(
  "@locations/createSenderPending"
)();
export const createSenderSuccess = createStandardAction(
  "@locations/createSenderSuccess"
)<CreateSenderResponse, { originalRequest: APIRequestConfig }>();

export const createSenderError = createStandardAction(
  "@locations/createSenderError"
)();

export const getLocationFromCustomerIdRequest = (id: string) => {
  return requestActionCreator("@locations/getLocationFromCustomerId")({
    request: {
      url: `/locations/public/${id}`,
      method: "GET",
      authenticated: false,
    },
  });
};
export const getLocationFromCustomerIdPending = createStandardAction(
  "@locations/getLocationFromCustomerIdPending"
)();
export const getLocationFromCustomerIdSuccess = createStandardAction(
  "@locations/getLocationFromCustomerIdSuccess"
)<IPublicLocation, { originalRequest: APIRequestConfig }>();
export const getLocationFromCustomerIdError = createStandardAction(
  "@locations/getLocationFromCustomerIdError"
)();

export const sendPlaceId = (payload: SendPlaceIdRequestPayload) => {
  return requestActionCreator("@locations/sendPlaceId")({
    request: {
      url: "/locations/save_place_id",
      method: "POST",
      authenticated: false,
      payload: {
        place_id: payload.placeId,
        place_name: payload.placeName,
        place_type: payload.placeType,
        source: payload.source,
        place_address: payload.placeAddress,
        timestamp: payload.timestamp,
        email: payload.email,
        target_grade: payload.targetGrade,
        phone_number: payload.phoneNumber,
      },
    },
  });
};

// export const createSenderPending = createStandardAction(
//   "@locations/createSenderPending"
// )();
// export const createSenderSuccess = createStandardAction(
//   "@locations/createSenderSuccess"
// )<CreateSenderResponse, { originalRequest: APIRequestConfig }>();

// export const createSenderError = createStandardAction(
//   "@locations/createSenderError"
// )();

export const getCompetitionAnalysis = (
  payload: CompetitionAnalysisRequestPayload
) => {
  return requestActionCreator("@locations/getCompetitionAnalysis")({
    request: {
      url: "/locations/competition_analysis",
      method: "POST",
      authenticated: false,
      payload: {
        place_id: payload.placeId,
        radius: payload.radius,
        category: payload.category,
        keyword: payload.keyword,
      },
    },
  });
};
export const getCompetitionAnalysisSuccess = createStandardAction(
  "@locations/getCompetitionAnalysisSuccess"
)<CompetitionAnalysisResponse, { originalRequest: APIRequestConfig }>();

// export const createSenderPending = createStandardAction(
//   "@locations/createSenderPending"
// )();

// export const createSenderError = createStandardAction(
//   "@locations/createSenderError"
// )();
