import { IJobTypeState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";

export type JobTypeAction = ActionType<typeof actions>;

const initialState: IJobTypeState = {
  data: [],
};

const reducer = createReducer(initialState).handleAction(
  actions.getJobTypesSuccess,
  (state, { payload, meta }) => ({
    ...state,
    data: payload,
  })
);

export { reducer as jobTypesReducer };
