import { AddCustomerData } from "../store/slices/customers/types";

export const mapCSVData: (
  data: string[][],
  email_address: string,
  phone_number: string,
  location_name: string
) => AddCustomerData[] = (data, email_address, phone_number, location_name) => {
  return data.slice(1).map((row) => ({
    location_name: location_name
      ? row[data[0].indexOf(location_name)] || null
      : null,
    email_address: row[data[0].indexOf(email_address)] || null,
    phone_number: row[data[0].indexOf(phone_number)] || null,
  }));
};
