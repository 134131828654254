import { createStandardAction } from "typesafe-actions";
import {
  AddCustomerData,
  AddCustomerPayload,
  AddCustomerResponse,
  CustomerCSVHeaders,
  LocationToSurveyMap,
} from "./types";
import { requestActionCreator } from "../../actionCreators";
import { APIRequestConfig } from "../../types";

export const addSingleCustomer = createStandardAction("@customers/addSingle")();
export const cancelAddSingleCustomer = createStandardAction(
  "@customers/cancelAddSingle"
)();
export const updateSingleCustomerDraft = createStandardAction(
  "@customers/updateSingleCustomerDraft"
)<Partial<AddCustomerData>>();

export const addCustomerRequest = ({
  email_address,
  phone_number,
  location_name,
}: AddCustomerData) => {
  return requestActionCreator("@customers/addCustomer")({
    request: {
      url: "/customers",
      method: "POST",
      authenticated: true,
      mock: {
        status: 200,
        body: {
          id: 123,
          email_address: "some@emailaddress.com",
          phoneNumber: "098q9w8750231",
        },
      },
      payload: {
        // don't send an empty string
        location_name: location_name || null,
        email_address: email_address || null,
        phone_number: phone_number || null,
      },
    },
  });
};

export const addCustomerPending = createStandardAction(
  "@customers/addCustomerPending"
)();
export const addCustomerSuccess = createStandardAction(
  "@customers/addCustomerSuccess"
)<AddCustomerResponse, { originalRequest: APIRequestConfig }>();
export const addCustomerError = createStandardAction(
  "@customers/addCustomerError"
)();

export const uploadCustomerCSV = createStandardAction(
  "@customers/uploadCustomerCSV"
)();
export const setUploadCustomerCSVModalPage = createStandardAction(
  "@customers/setUploadCustomerCSVModalPage"
)<1 | 2>();
export const cancelUploadCustomerCSV = createStandardAction(
  "@customers/cancelUploadCustomerCSV"
)();
export const loadCustomerCSVData = createStandardAction(
  "@customers/loadCustomerCSVData"
)<string[][]>();
export const selectCustomerCSVHeader = createStandardAction(
  "@customers/selectCustomerCSVHeader"
)<Partial<CustomerCSVHeaders>>();

export const setForcedLocationName = createStandardAction(
  "@customers/setForcedLocationName"
)<string>();

export const setLocationToSurveyMap = createStandardAction(
  "@customers/setLocationToSurveyMap"
)<LocationToSurveyMap>();

export const saveNextCSVChunk = createStandardAction(
  "@customers/saveNextCSVChunk"
)();
export const chunkedUploadComplete = createStandardAction(
  "@customers/chunkedUploadComplete"
)();

export const saveCustomerCSVData = (
  payload: AddCustomerPayload[],
  bulk_upload_id: string | null
) => {
  return requestActionCreator("@customers/saveCustomerCSVData")({
    request: {
      url: "/customers/bulk",
      method: "POST",
      authenticated: true,
      params: bulk_upload_id ? { bulk_upload_id } : {},
      mock: {
        status: 200,
        body: {
          data: [
            {
              id: 123,
              email_address: "some@emailaddress.com",
              phoneNumber: "098q9w8750231",
            },
          ],
        },
      },
      payload,
    },
  });
};

export const saveCustomerCSVDataPending = createStandardAction(
  "@customers/saveCustomerCSVDataPending"
)();
export const saveCustomerCSVDataSuccess = createStandardAction(
  "@customers/saveCustomerCSVDataSuccess"
)<{ bulk_upload_id: string }, { originalRequest: APIRequestConfig }>();

export const saveCustomerCSVDataError = createStandardAction(
  "@customers/saveCustomerCSVDataError"
)<{ detail?: string; code?: string; extra_data?: { [key: string]: any } }>();

export const updateCustomerCSVLocation = createStandardAction(
  "@customers/updateCustomerCSVLocation"
)<string>();

export const mapLocationName = createStandardAction(
  "@customers/mapLocationName"
)<{ raw: string; standardised: string | null }>();
