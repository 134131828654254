import { createStandardAction } from "typesafe-actions";
import { requestActionCreator } from "../../actionCreators";
import { APIRequestConfig } from "../../types";

export const getSendinblueSendersRequest = () => {
  return requestActionCreator("@sendinblue/getSendinblueSenders")({
    request: {
      url: "/sendinblue/sender",
      method: "GET",
    },
  });
};

export const getSendinblueSendersPending = createStandardAction(
  "@sendinblue/getSendinblueSendersPending"
)();
export const getSendinblueSendersSuccess = createStandardAction(
  "@sendinblue/getSendinblueSendersSuccess"
)<any, { request: APIRequestConfig }>();
