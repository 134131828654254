import React, { useEffect } from "react";
import AuthCheck from "./components/layout/AuthCheck";
import { useDispatch, useSelector } from "react-redux";
import { loadCache } from "./store/slices/account/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDomainConfigsRequest } from "./store/slices/whitelabelling/actions";
import { Route, Switch } from "react-router-dom";
import Survey from "./components/views/Survey";
import CompetitionMap from "./components/views/CompetitionMap";
import LandingPageMap from "./components/views/LandingPageMap";
import useCurrentDomainConfig from "./hooks/useCurrentDomainConfig";
import { selectGotDomainConfig } from "./store/slices/whitelabelling/selectors";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCache());
    dispatch(getDomainConfigsRequest());
  }, [dispatch]);

  const domainConfig = useCurrentDomainConfig();
  const gotDomainConfig = useSelector(selectGotDomainConfig);

  useEffect(() => {
    if (domainConfig && gotDomainConfig) {
      document.title = domainConfig.site_title || "QualiService";
      const favicon = document.getElementById("favicon") as any;
      if (favicon && favicon.href) {
        favicon.href =
          domainConfig.favicon || domainConfig.logo || "/favicon.ico";
      }
    }
  }, [domainConfig, gotDomainConfig]);

  return (
    <>
      <Switch>
        <Route path="/landing-map">
          <LandingPageMap />
        </Route>
        <Route path="/survey/:customerId">
          <Survey />
        </Route>
        <Route path="/competition-map/:placeId">
          <CompetitionMap />
        </Route>
        <Route path="*">
          <AuthCheck />
        </Route>
      </Switch>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
};

export default App;
