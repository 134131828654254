import { INotificationsState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";

export type NotificationsAction = ActionType<typeof actions>;

const initialState: INotificationsState = {
  data: {
    settings: {
      usage: null,
      negative: null,
    },
  },
  ui: {
    isLoading: false,
    isUpdating: false,
    isNegativeLoading: false,
    isNegativeUpdating: false,
  },
};

const reducer = createReducer(initialState)
  .handleAction(actions.getNotificationSettingsPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        isLoading: true,
      },
    };
  })
  .handleAction(
    actions.getNotificationSettingsSuccess,
    (state, { payload }) => {
      return {
        ...state,
        data: {
          settings: {
            ...state.data.settings,
            usage: payload,
          },
        },
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };
    }
  )
  .handleAction(actions.getNotificationSettingsError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        isLoading: false,
      },
    };
  })
  .handleAction(actions.updateNotificationSettingsPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        isUpdating: true,
      },
    };
  })
  .handleAction(
    actions.updateNotificationSettingsSuccess,
    (state, { payload }) => {
      return {
        ...state,
        data: {
          settings: {
            ...state.data.settings,
            usage: payload,
          },
        },
        ui: {
          ...state.ui,
          isUpdating: false,
        },
      };
    }
  )
  .handleAction(actions.updateNotificationSettingsError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        isUpdating: false,
      },
    };
  })
  // negative
  .handleAction(actions.getNegativeNotificationSettingsPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        isNegativeLoading: true,
      },
    };
  })
  .handleAction(
    actions.getNegativeNotificationSettingsSuccess,
    (state, { payload }) => {
      return {
        ...state,
        data: {
          settings: {
            ...state.data.settings,
            negative: payload,
          },
        },
        ui: {
          ...state.ui,
          isNegativeLoading: false,
        },
      };
    }
  )
  .handleAction(actions.getNegativeNotificationSettingsError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        isNegativeLoading: false,
      },
    };
  })
  .handleAction(actions.updateNegativeNotificationSettingsPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        isNegativeUpdating: true,
      },
    };
  })
  .handleAction(
    actions.updateNegativeNotificationSettingsSuccess,
    (state, { payload }) => {
      return {
        ...state,
        data: {
          settings: {
            ...state.data.settings,
            negative: payload,
          },
        },
        ui: {
          ...state.ui,
          isNegativeUpdating: false,
        },
      };
    }
  )
  .handleAction(actions.updateNegativeNotificationSettingsError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        isNegativeUpdating: false,
      },
    };
  });

export { reducer as notificationsReducer };
