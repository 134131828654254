import { AddCustomerData } from "../store/slices/customers/types";

export const deDupeCSVData: (raw: AddCustomerData[]) => AddCustomerData[] = (
  raw
) => {
  const emails: { [key: string]: number } = {};
  const phoneNumbers: { [key: string]: number } = {};
  const filtered: AddCustomerData[] = [];

  raw.forEach(({ email_address, phone_number, location_name }) => {
    let isDuplicate = false;

    // update email address tracker if this customer has an email
    if (email_address) {
      if (!emails.hasOwnProperty(email_address)) {
        emails[email_address] = 0;
      }
      emails[email_address]++;

      // if there is more than one customer with this email, mark as duplicate
      if (emails[email_address] > 1) {
        isDuplicate = true;
      }
    }

    // update phone number tracker if this customer has an phone number
    if (phone_number) {
      if (!phoneNumbers.hasOwnProperty(phone_number)) {
        phoneNumbers[phone_number] = 0;
      }
      phoneNumbers[phone_number]++;

      // if there is more than one customer with this phone number, mark as duplicate
      if (phoneNumbers[phone_number] > 1) {
        isDuplicate = true;
      }
    }

    if (!isDuplicate) {
      filtered.push({ email_address, phone_number, location_name });
    }
  });
  return filtered;
};
