import React, { useEffect, useState } from "react";
import Map, { Marker, Popup } from "react-map-gl";

import { useDispatch, useSelector } from "react-redux";
import { getCompetitionAnalysis } from "../../store/slices/locations/actions";
import { useLocation, useParams } from "react-router-dom";
import {
  selectCompetitors,
  selectSearchedLocation,
} from "../../store/slices/locations/selectors";
import "mapbox-gl/dist/mapbox-gl.css";
import MarkerIcon from "../../assets/marker.svg";
import LocationIcon from "../../assets/location-circle.svg";
import Spinner from "../atoms/Spinner";
import { parse } from "query-string";

export default function CompetitionMap() {
  const { placeId } = useParams<{ placeId: string }>();
  const competitors = useSelector(selectCompetitors);
  const searchedLocation = useSelector(selectSearchedLocation);

  const [selectedLocation, setSelectedLocation] = useState("");

  const dispatch = useDispatch();

  const { search } = useLocation();

  const { category, keyword } = parse(search) as {
    category: string | undefined;
    keyword: string | undefined;
  };

  useEffect(() => {
    dispatch(
      getCompetitionAnalysis({
        placeId,
        radius: 10000,
        category: category || "",
        keyword: keyword || "",
      })
    );
  }, [placeId, category, keyword, dispatch]);

  if (competitors.length === 0 || !searchedLocation) {
    return <Spinner theme="dark" />;
  }

  const initialLat = searchedLocation.lat;
  const initialLng = searchedLocation.lng;

  return (
    <div className="tw-h-full">
      <Map
        mapStyle="mapbox://styles/mapbox/streets-v12"
        style={{
          height: "100%",
          width: "100%",
        }}
        initialViewState={{
          longitude: initialLng,
          latitude: initialLat,
          zoom: 12,
        }}
      >
        {[searchedLocation, ...competitors].map((competitor) => {
          const locationId = `${competitor.lat},${competitor.lng}`;
          return (
            <div key={locationId}>
              <Marker
                longitude={competitor.lng}
                latitude={competitor.lat}
                color="blue"
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  setSelectedLocation(locationId);
                }}
                style={placeId === competitor.place_id ? { zIndex: 50 } : {}}
              >
                <img
                  src={
                    placeId === competitor.place_id ? LocationIcon : MarkerIcon
                  }
                  className="tw-h-8"
                  alt="marker"
                />
              </Marker>
              {selectedLocation === locationId && (
                <Popup
                  anchor="top"
                  longitude={competitor.lng}
                  latitude={competitor.lat}
                  onClose={() => setSelectedLocation("")}
                >
                  <div className="tw-p-4 tw-rounded-lg">
                    <div className="tw-font-bold tw-text-md">
                      {competitor.name}
                    </div>
                    <div>{competitor.address}</div>
                    <div>Num reviews: {competitor.num_reviews}</div>
                    <div>Rating: {competitor.rating}</div>
                  </div>
                </Popup>
              )}
            </div>
          );
        })}
      </Map>
    </div>
  );
}
