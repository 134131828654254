import { createStandardAction } from "typesafe-actions";
import { TypeConstant } from "typesafe-actions/dist/type-helpers";
import { APIRequestConfig } from "./types";

export const requestActionCreator = (type: TypeConstant) => {
  const creator = createStandardAction(type)<
    undefined,
    { request: APIRequestConfig }
  >();
  return (meta: { request: APIRequestConfig }) => creator(undefined, meta);
};

// export const createRequestPendingAction: (requestActionType: TypeConstant) => EmptyAC<TypeConstant> = (requestActionType) => {
//   return createStandardAction(requestActionType)();
// };
//
// export const createResponseSuccessAction = <TBody>(requestActionType: TypeConstant) => {
//   return createStandardAction(requestActionType)<TBody, {originalRequest: APIRequestConfig}>();
// };
//
// export const createResponseErrorAction = <TErr = any>(requestActionType: TypeConstant) => {
//   return createStandardAction(`${requestActionType}Error`)<TErr, {originalRequest: APIRequestConfig}>();
// };
