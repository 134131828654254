import { ISurveyState, SurveyPageName } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";

export type SurveysAction = ActionType<typeof actions>;

const initialState: ISurveyState = {
  data: {
    surveys: {
      ids: [],
      byId: {},
    },
    globalSurveys: {
      ids: [],
      byId: {},
    },
    supplementarySurveys: {
      ids: [],
      byId: {},
    },
    customerSurvey: null,
    customerSurveyResponseId: null,
    newSurveyInitialData: null,
    locationRedirectUrl: "",
    surveyMetrics: {},
    selectedSurveyForMetrics: null,
    usingGlobalSurveyMetrics: false,
    hasLpData: false,
  },
  ui: {
    createdModalOpen: false,
    createSurveyPending: false,
    patchSurveyPending: false,
    surveyOptionsOpen: 0,
    getCustomerSurveyPending: false,
    getSurveysPending: false,
    createCustomSurveyResponsePending: false,
    updateCustomSurveyResponsePending: false,
    surveyPage: "START",
    surveyMetrics: {
      getSurveyMetricsPending: false,
    },
  },
};

const reducer = createReducer(initialState)
  .handleAction(actions.createSurveyPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        createSurveyPending: true,
      },
    };
  })
  .handleAction(actions.createSupplementarySurveyPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        createSurveyPending: true,
      },
    };
  })
  .handleAction(actions.createSurveySuccess, (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        surveys: {
          ...state.data.surveys,
          ids: [...state.data.surveys.ids, payload.id],
          byId: {
            ...state.data.surveys.byId,
            [payload.id]: payload,
          },
        },
      },
      ui: {
        ...state.ui,
        createdModalOpen: true,
        createSurveyPending: false,
        surveyOptionsOpen: 0,
      },
    };
  })
  .handleAction(
    actions.createSupplementarySurveySuccess,
    (state, { payload }) => {
      return {
        ...state,
        data: {
          ...state.data,
          supplementarySurveys: {
            ...state.data.supplementarySurveys,
            ids: [...state.data.supplementarySurveys.ids, payload.id],
            byId: {
              ...state.data.supplementarySurveys.byId,
              [payload.id]: payload,
            },
          },
        },
        ui: {
          ...state.ui,
          createdModalOpen: true,
          createSurveyPending: false,
          surveyOptionsOpen: 0,
        },
      };
    }
  )
  .handleAction(actions.createSurveyError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        createSurveyPending: false,
      },
    };
  })
  .handleAction(actions.createSupplementarySurveyError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        createSurveyPending: false,
      },
    };
  })
  .handleAction(actions.createCustomSurveyResponsePending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        createCustomSurveyResponsePending: true,
      },
    };
  })
  .handleAction(actions.updateCustomSurveyResponsePending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        updateCustomSurveyResponsePending: true,
      },
    };
  })
  .handleAction(actions.updateGlobalCustomSurveyResponsePending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        updateCustomSurveyResponsePending: true,
      },
    };
  })
  .handleAction(actions.createGlobalCustomSurveyResponsePending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        createCustomSurveyResponsePending: true,
      },
    };
  })
  .handleAction(
    actions.createCustomSurveyResponseSuccess,
    (
      state,
      {
        payload,
        meta: {
          originalRequest: {
            payload: { useConditionalQuestions, useSupplementaryForm },
          },
        },
      }
    ) => {
      const isNegative = payload.is_negative;

      let nextPage: SurveyPageName = "FINISHED_POSITIVE";
      if (isNegative) {
        nextPage = "FINISHED_NEGATIVE";
        if (useConditionalQuestions) {
          nextPage = "CONDITIONAL_QUESTIONS";
        }
        if (useSupplementaryForm) {
          nextPage = "SUPPLEMENTARY_FORM";
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          locationRedirectUrl: payload.redirect_url,
          customerSurveyResponseId: payload.id,
        },
        ui: {
          ...state.ui,
          createCustomSurveyResponsePending: false,
          surveyPage: nextPage,
        },
      };
    }
  )
  .handleAction(actions.updateCustomSurveyResponseSuccess, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        updateCustomSurveyResponsePending: false,
        surveyPage: "FINISHED_NEGATIVE",
      },
    };
  })
  .handleAction(actions.updateGlobalCustomSurveyResponseSuccess, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        updateCustomSurveyResponsePending: false,
        surveyPage: "FINISHED_NEGATIVE",
      },
    };
  })
  .handleAction(
    actions.createGlobalCustomSurveyResponseSuccess,
    (
      state,
      {
        payload,
        meta: {
          originalRequest: {
            payload: { useConditionalQuestions },
          },
        },
      }
    ) => {
      const isNegative = payload.is_negative;
      return {
        ...state,
        data: {
          ...state.data,
          locationRedirectUrl: payload.redirect_url,
          customerSurveyResponseId: payload.id,
        },
        ui: {
          ...state.ui,
          createCustomSurveyResponsePending: false,
          surveyPage: isNegative
            ? useConditionalQuestions
              ? "CONDITIONAL_QUESTIONS"
              : "FINISHED_NEGATIVE"
            : "FINISHED_POSITIVE",
        },
      };
    }
  )
  .handleAction(actions.createCustomSurveyResponseError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        createCustomSurveyResponsePending: false,
      },
    };
  })
  .handleAction(actions.updateCustomSurveyResponseError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        updateCustomSurveyResponsePending: false,
      },
    };
  })
  .handleAction(actions.updateGlobalCustomSurveyResponseError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        updateCustomSurveyResponsePending: false,
      },
    };
  })
  .handleAction(actions.createGlobalCustomSurveyResponseError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        createCustomSurveyResponsePending: false,
      },
    };
  })
  .handleAction(actions.patchSurveyPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: true,
      },
    };
  })
  .handleAction(actions.patchSupplementarySurveyPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: true,
      },
    };
  })
  .handleAction(actions.patchSurveySuccess, (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        surveys: {
          ...state.data.surveys,
          byId: {
            ...state.data.surveys.byId,
            [payload.id]: payload,
          },
        },
      },
      ui: {
        ...state.ui,
        patchSurveyPending: false,
        surveyOptionsOpen: 0,
      },
    };
  })
  .handleAction(
    actions.patchSupplementarySurveySuccess,
    (state, { payload }) => {
      return {
        ...state,
        data: {
          ...state.data,
          supplementarySurveys: {
            ...state.data.supplementarySurveys,
            byId: {
              ...state.data.supplementarySurveys.byId,
              [payload.id]: payload,
            },
          },
        },
        ui: {
          ...state.ui,
          patchSurveyPending: false,
          surveyOptionsOpen: 0,
        },
      };
    }
  )
  .handleAction(actions.patchSurveyError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: false,
      },
    };
  })
  .handleAction(actions.patchSupplementarySurveyError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: false,
      },
    };
  })
  .handleAction(actions.archiveSurveyPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: true,
      },
    };
  })
  .handleAction(actions.archiveSurveySuccess, (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        surveys: {
          ...state.data.surveys,
          byId: {
            ...state.data.surveys.byId,
            [payload.id]: payload,
          },
        },
      },
      ui: {
        ...state.ui,
        patchSurveyPending: false,
        surveyOptionsOpen: 0,
        supplementarySurveyOptionsOpen: 0,
      },
    };
  })
  .handleAction(actions.archiveSurveyError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: false,
      },
    };
  })
  .handleAction(actions.archiveSupplementarySurveyPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: true,
      },
    };
  })
  .handleAction(
    actions.archiveSupplementarySurveySuccess,
    (state, { payload }) => {
      return {
        ...state,
        data: {
          ...state.data,
          supplementarySurveys: {
            ...state.data.supplementarySurveys,
            byId: {
              ...state.data.supplementarySurveys.byId,
              [payload.id]: payload,
            },
          },
        },
        ui: {
          ...state.ui,
          patchSurveyPending: false,
          surveyOptionsOpen: 0,
          supplementarySurveyOptionsOpen: 0,
        },
      };
    }
  )
  .handleAction(actions.archiveSupplementarySurveyError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: false,
      },
    };
  })
  .handleAction(actions.unarchiveSurveyPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: true,
      },
    };
  })
  .handleAction(actions.unarchiveSurveySuccess, (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        surveys: {
          ...state.data.surveys,
          byId: {
            ...state.data.surveys.byId,
            [payload.id]: payload,
          },
        },
      },
      ui: {
        ...state.ui,
        patchSurveyPending: false,
        surveyOptionsOpen: 0,
        supplementarySurveyOptionsOpen: 0,
      },
    };
  })
  .handleAction(actions.unarchiveSurveyError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: false,
      },
    };
  })
  .handleAction(actions.unarchiveSupplementarySurveyPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: true,
      },
    };
  })
  .handleAction(
    actions.unarchiveSupplementarySurveySuccess,
    (state, { payload }) => {
      return {
        ...state,
        data: {
          ...state.data,
          supplementarySurveys: {
            ...state.data.supplementarySurveys,
            byId: {
              ...state.data.supplementarySurveys.byId,
              [payload.id]: payload,
            },
          },
        },
        ui: {
          ...state.ui,
          patchSurveyPending: false,
          surveyOptionsOpen: 0,
          supplementarySurveyOptionsOpen: 0,
        },
      };
    }
  )
  .handleAction(actions.unarchiveSupplementarySurveyError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        patchSurveyPending: false,
      },
    };
  })
  .handleAction(actions.getSurveysPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        getSurveysPending: true,
      },
    };
  })
  .handleAction(actions.getSupplementarySurveysPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        getSurveysPending: true,
      },
    };
  })
  .handleAction(actions.getSurveysSuccess, (state, { payload: results }) => {
    return {
      ...state,
      data: {
        ...state.data,
        surveys: {
          ...state.data.surveys,
          ids: results.map(({ id }) => id),
          byId: results.reduce(
            (prev, next) => ({
              ...prev,
              [next.id]: next,
            }),
            {}
          ),
        },
      },
      ui: {
        ...state.ui,
        getSurveysPending: false,
      },
    };
  })
  .handleAction(
    actions.getSupplementarySurveysSuccess,
    (state, { payload: results }) => {
      return {
        ...state,
        data: {
          ...state.data,
          supplementarySurveys: {
            ...state.data.supplementarySurveys,
            ids: results.map(({ id }) => id),
            byId: results.reduce(
              (prev, next) => ({
                ...prev,
                [next.id]: next,
              }),
              {}
            ),
          },
        },
        ui: {
          ...state.ui,
          getSurveysPending: false,
        },
      };
    }
  )
  .handleAction(actions.getSurveysError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        getSurveysPending: false,
      },
    };
  })
  .handleAction(actions.getSupplementarySurveysError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        getSurveysPending: false,
      },
    };
  })
  .handleAction(
    actions.getGlobalSurveysSuccess,
    (state, { payload: results }) => {
      return {
        ...state,
        data: {
          ...state.data,
          globalSurveys: {
            ...state.data.globalSurveys,
            ids: results.map(({ id }) => id),
            byId: results.reduce(
              (prev, next) => ({
                ...prev,
                [next.id]: next,
              }),
              {}
            ),
          },
        },
      };
    }
  )
  .handleAction(actions.getCustomSurveyStatisticsPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        surveyMetrics: {
          ...state.ui.surveyMetrics,
          getSurveyMetricsPending: true,
        },
      },
    };
  })
  .handleAction(actions.getLpSurveyStatisticsPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        surveyMetrics: {
          ...state.ui.surveyMetrics,
          getSurveyMetricsPending: true,
        },
      },
    };
  })
  .handleAction(actions.getGlobalCustomSurveyStatisticsPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        surveyMetrics: {
          ...state.ui.surveyMetrics,
          getSurveyMetricsPending: true,
        },
      },
    };
  })
  .handleAction(
    actions.getCustomSurveyStatisticsSuccess,
    (state, { payload: result }) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          surveyMetrics: {
            ...state.ui.surveyMetrics,
            getSurveyMetricsPending: false,
          },
        },
        data: {
          ...state.data,
          surveyMetrics: result,
        },
      };
    }
  )
  .handleAction(
    actions.getLpSurveyStatisticsSuccess,
    (state, { payload: result }) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          surveyMetrics: {
            ...state.ui.surveyMetrics,
            getSurveyMetricsPending: false,
          },
        },
        data: {
          ...state.data,
          surveyMetrics: result,
        },
      };
    }
  )
  .handleAction(
    actions.getGlobalCustomSurveyStatisticsSuccess,
    (state, { payload: result }) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          surveyMetrics: {
            ...state.ui.surveyMetrics,
            getSurveyMetricsPending: false,
          },
        },
        data: {
          ...state.data,
          surveyMetrics: result,
        },
      };
    }
  )
  .handleAction(actions.getHasLpDataSuccess, (state, { payload: result }) => {
    return {
      ...state,
      data: {
        ...state.data,
        hasLpData: result.has_data,
      },
    };
  })
  .handleAction(actions.setUsingGlobalSurveyMetrics, (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        usingGlobalSurveyMetrics: payload,
      },
    };
  })
  .handleAction(actions.getCustomerSurveyPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        getCustomerSurveyPending: true,
      },
    };
  })
  .handleAction(actions.getCustomerSurveyError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        getCustomerSurveyPending: false,
      },
    };
  })
  .handleAction(
    actions.getCustomerSurveySuccess,
    (state, { payload: result }) => {
      return {
        ...state,
        data: {
          ...state.data,
          customerSurvey: result,
        },
        ui: {
          ...state.ui,
          getCustomerSurveyPending: false,
        },
      };
    }
  )
  .handleAction(actions.closeSurveyCreatedModal, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      createdModalOpen: false,
    },
  }))
  .handleAction(actions.openSurveyCreatedModal, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      createdModalOpen: true,
    },
  }))
  .handleAction(actions.closeSurveyOptionsModal, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      surveyOptionsOpen: 0,
    },
  }))
  .handleAction(actions.openSurveyOptionsModal, (state, { payload }) => ({
    ...state,
    ui: {
      ...state.ui,
      surveyOptionsOpen: payload,
    },
  }))
  .handleAction(actions.closeSupplementarySurveyOptionsModal, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      supplementarySurveyOptionsOpen: 0,
    },
  }))
  .handleAction(
    actions.openSupplementarySurveyOptionsModal,
    (state, { payload }) => ({
      ...state,
      ui: {
        ...state.ui,
        supplementarySurveyOptionsOpen: payload,
      },
    })
  )
  .handleAction(actions.setSurveyPage, (state, { payload }) => ({
    ...state,
    ui: {
      ...state.ui,
      surveyPage: payload,
    },
  }))
  .handleAction(actions.setNewSurveyInitialData, (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      newSurveyInitialData: payload,
    },
  }))
  .handleAction(actions.setSelectedSurveyForMetrics, (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      selectedSurveyForMetrics: payload,
    },
  }));

export { reducer as surveysReducer };
