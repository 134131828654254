import { createStandardAction } from "typesafe-actions";
import { requestActionCreator } from "../../actionCreators";
import { APIRequestConfig } from "../../types";

export const getDomainConfigsRequest = () => {
  return requestActionCreator("@whiteLabelling/getDomainConfigs")({
    request: {
      url: "/whitelabelling/domain_config",
      method: "GET",
    },
  });
};

export const getDomainConfigsPending = createStandardAction(
  "@whiteLabelling/getDomainConfigsPending"
)();
export const getDomainConfigsSuccess = createStandardAction(
  "@whiteLabelling/getDomainConfigsSuccess"
)<any, { request: APIRequestConfig }>();
