import { createStandardAction } from "typesafe-actions";
import { requestActionCreator } from "../../actionCreators";
import { APIRequestConfig } from "../../types";

export const getStatisticsRequest = (
  gmbLocationId: number,
  pageToken?: string
) => {
  return requestActionCreator("@statistics/getStatistics")({
    request: {
      url: `/gmb/statistics/${gmbLocationId}`,
      method: "GET",
      params: {
        ...(pageToken ? { page_token: pageToken } : {}),
      },
    },
  });
};

export const getStatisticsPending = createStandardAction(
  "@statistics/getStatisticsPending"
)();
export const getStatisticsSuccess = createStandardAction(
  "@statistics/getStatisticsSuccess"
  // )<IGmbReviewResponse, { originalRequest: APIRequestConfig }>();
)<any, { originalRequest: APIRequestConfig }>();

export const getAllStatisticsRequest = (
  startDate?: string,
  endDate?: string
) => {
  return requestActionCreator("@statistics/getAllStatistics")({
    request: {
      url: `/statistics/locations`,
      method: "GET",
      ...{
        params: {
          start_time: startDate || "",
          end_time: endDate || "",
        },
      },
    },
  });
};

export const getAllStatisticsPending = createStandardAction(
  "@statistics/getAllStatisticsPending"
)();
export const getAllStatisticsSuccess = createStandardAction(
  "@statistics/getAllStatisticsSuccess"
  // )<IGmbReviewAllResponse, { originalRequest: APIRequestConfig }>();
)<any, { originalRequest: APIRequestConfig }>();
