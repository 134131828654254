import { ILocationState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";

export type LocationsAction = ActionType<typeof actions>;

const initialState: ILocationState = {
  data: {
    locations: {
      ids: [],
      byId: {},
    },
    locationSummaries: {
      ids: [],
      byId: {},
    },
    gmb_locations: {
      ids: [],
      byId: {},
    },
    competitors: [],
    searchedLocation: null,
    customerLocation: null,
  },
  ui: {
    createSenderPending: false,
    getCustomerLocationPending: false,
    locations: {
      hasLoaded: false,
      pagination: null,
    },
    locationSummaries: {
      isLoading: false,
      pagination: null,
    },
    addLocation: {
      modalIsOpen: false,
      isPending: false,
      page: 1,
    },
    setGmbLocationModal: {
      isOpen: false,
      isLoading: false,
      isPending: false,
      selectedGmbLocation: null,
      selectedId: 0,
    },
    editLocationModal: {
      isOpen: false,
      isPending: false,
      selectedId: null,
    },
    deleteLocationModal: {
      isOpen: false,
      isPending: false,
      selectedId: null,
    },
    qrModal: {
      isOpen: false,
    },
  },
};

const reducer = createReducer(initialState)
  .handleAction(actions.openAddLocationModal, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        addLocation: {
          ...state.ui.addLocation,
          modalIsOpen: true,
        },
      },
    };
  })
  .handleAction(actions.closeAddLocationModal, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        addLocation: {
          ...state.ui.addLocation,
          modalIsOpen: false,
        },
      },
    };
  })
  .handleAction(actions.openQrModal, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        qrModal: {
          ...state.ui.qrModal,
          isOpen: true,
        },
      },
    };
  })
  .handleAction(actions.closeQrModal, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        qrModal: {
          ...state.ui.qrModal,
          isOpen: false,
        },
      },
    };
  })
  .handleAction(actions.createLocationPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        addLocation: {
          ...state.ui.addLocation,
          isPending: true,
        },
      },
    };
  })
  .handleAction(actions.createLocationSuccess, (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        locations: {
          ...state.data.locations,
          ids: [...state.data.locations.ids, payload.id],
          byId: {
            ...state.data.locations.byId,
            [payload.id]: {
              ...payload,
              gmb_location: payload.gmb_location
                ? payload.gmb_location.id
                : null,
            },
          },
        },
        gmb_locations: {
          ...state.data.gmb_locations,
          byId: {
            ...state.data.gmb_locations.byId,
            ...(payload.gmb_location
              ? {
                  [payload.gmb_location.id]: {
                    ...payload.gmb_location,
                  },
                }
              : {}),
          },
        },
      },
      ui: {
        ...state.ui,
        addLocation: {
          ...state.ui.addLocation,
          modalIsOpen: false,
          isPending: false,
        },
      },
    };
  })
  .handleAction(
    actions.getLocationsSuccess,
    (state, { payload: { results, pagination } }) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          locations: {
            ...state.ui.locations,
            hasLoaded: true,
            pagination,
          },
        },
        data: {
          ...state.data,
          locations: {
            ...state.data.locations,
            ids: results.map(({ id }) => id),
            byId: results.reduce(
              (prev, next) => ({
                ...prev,
                [next.id]: {
                  ...next,
                  gmb_location: next.gmb_location ? next.gmb_location.id : null,
                },
              }),
              {}
            ),
          },
          gmb_locations: {
            ...state.data.gmb_locations,
            byId: {
              ...state.data.gmb_locations.byId,
              ...results.reduce(
                (prev, next) => ({
                  ...prev,
                  ...(next.gmb_location
                    ? {
                        [next.gmb_location.id]: {
                          ...next.gmb_location,
                        },
                      }
                    : {}),
                }),
                {}
              ),
            },
          },
        },
      };
    }
  )
  .handleAction(actions.getLocationSummariesPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        locationSummaries: {
          ...state.ui.locationSummaries,
          isLoading: true,
        },
      },
    };
  })
  .handleAction(
    actions.getLocationSummariesSuccess,
    (state, { payload: { results, pagination } }) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          locationSummaries: {
            ...state.ui.locationSummaries,
            pagination,
            isLoading: false,
          },
        },
        data: {
          ...state.data,
          locationSummaries: {
            ...state.data.locationSummaries,
            ids: results.map(({ id }) => id),
            byId: results.reduce(
              (prev, { id, name }) => ({
                ...prev,
                [id]: { id, name },
              }),
              {}
            ),
          },
        },
      };
    }
  )
  .handleAction(actions.getLocationSuccess, (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        locations: {
          ...state.data.locations,
          ids: [...state.data.locations.ids, payload.id].filter(
            (id, index, self) => self.indexOf(id) === index
          ),
          byId: {
            ...state.data.locations.byId,
            [payload.id]: {
              ...payload,
              gmb_location: payload.gmb_location
                ? payload.gmb_location.id
                : null,
            },
          },
        },
        gmb_locations: {
          ...state.data.gmb_locations,
          byId: {
            ...state.data.gmb_locations.byId,
            ...(payload.gmb_location
              ? {
                  [payload.gmb_location.id]: {
                    ...payload.gmb_location,
                  },
                }
              : {}),
          },
        },
      },
    };
  })
  .handleAction(actions.getLocationFromCustomerIdPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        getCustomerLocationPending: true,
      },
    };
  })
  .handleAction(
    actions.getLocationFromCustomerIdSuccess,
    (state, { payload }) => {
      return {
        ...state,
        data: {
          ...state.data,
          customerLocation: payload,
        },
        ui: {
          ...state.ui,
          getCustomerLocationPending: false,
        },
      };
    }
  )
  .handleAction(actions.getCompetitionAnalysisSuccess, (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        competitors: payload.competitors,
        searchedLocation: payload.searched_location,
      },
    };
  })
  .handleAction(actions.getLocationFromCustomerIdError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        getCustomerLocationPending: false,
      },
    };
  })
  .handleAction(actions.openSetGmbLocationModal, (state, { payload }) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        setGmbLocationModal: {
          ...state.ui.setGmbLocationModal,
          isOpen: true,
          selectedGmbLocation: null,
          selectedId: payload,
        },
      },
    };
  })
  .handleAction(actions.closeSetGmbLocationModal, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        setGmbLocationModal: {
          ...state.ui.setGmbLocationModal,
          isOpen: false,
        },
      },
    };
  })
  .handleAction(actions.openEditLocationModal, (state, { payload }) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        editLocationModal: {
          ...state.ui.editLocationModal,
          selectedId: payload,
          isOpen: true,
        },
      },
    };
  })
  .handleAction(actions.closeEditLocationModal, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        editLocationModal: {
          ...state.ui.editLocationModal,
          selectedId: null,
          isOpen: false,
        },
      },
    };
  })
  .handleAction(actions.openDeleteLocationModal, (state, { payload }) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        deleteLocationModal: {
          ...state.ui.deleteLocationModal,
          selectedId: payload,
          isOpen: true,
        },
      },
    };
  })
  .handleAction(actions.closeDeleteLocationModal, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        deleteLocationModal: {
          ...state.ui.deleteLocationModal,
          isOpen: false,
        },
      },
    };
  })
  .handleAction(actions.getGmbLocationsPending, (state) => ({
    ...state,
    data: {
      ...state.data,
      gmb_locations: {
        ids: [],
        byId: {},
      },
    },
    ui: {
      ...state.ui,
      setGmbLocationModal: {
        ...state.ui.setGmbLocationModal,
        isLoading: true,
      },
    },
  }))
  .handleAction(
    actions.getGmbLocationsSuccess,
    (state, { payload: { results } }) => {
      return {
        ...state,
        data: {
          ...state.data,
          gmb_locations: {
            ...state.data.gmb_locations,
            ids: results.map(({ id }) => id),
            byId: results.reduce(
              (prev, next) => ({
                ...prev,
                [next.id]: next,
              }),
              {}
            ),
          },
        },
        ui: {
          ...state.ui,
          setGmbLocationModal: {
            ...state.ui.setGmbLocationModal,
            isLoading: false,
          },
        },
      };
    }
  )
  .handleAction(actions.selectGmbLocation, (state, { payload }) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        setGmbLocationModal: {
          ...state.ui.setGmbLocationModal,
          selectedGmbLocation: payload,
        },
      },
    };
  })
  .handleAction(actions.patchGmbLocationPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        setGmbLocationModal: {
          ...state.ui.setGmbLocationModal,
          isPending: true,
        },
      },
    };
  })
  .handleAction(actions.patchGmbLocationSuccess, (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        locations: {
          ...state.data.locations,
          byId: {
            ...state.data.locations.byId,
            ...(payload.location
              ? {
                  [payload.location]: {
                    ...state.data.locations.byId[payload.location],
                    gmb_location: payload.id,
                  },
                }
              : {}),
          },
        },
        gmb_locations: {
          ...state.data.gmb_locations,
          byId: {
            ...state.data.gmb_locations.byId,
            [payload.id]: {
              ...payload,
            },
          },
        },
      },
      ui: {
        ...state.ui,
        setGmbLocationModal: {
          ...state.ui.setGmbLocationModal,
          isOpen: false,
          isPending: false,
        },
      },
    };
  })
  .handleAction(actions.patchLocationPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        editLocationModal: {
          ...state.ui.editLocationModal,
          isPending: true,
        },
      },
    };
  })
  .handleAction(actions.patchLocationError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        editLocationModal: {
          ...state.ui.editLocationModal,
          isPending: false,
        },
      },
    };
  })
  .handleAction(actions.patchLocationSuccess, (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        locations: {
          ...state.data.locations,
          byId: {
            ...state.data.locations.byId,
            [payload.id]: {
              ...payload,
              gmb_location: payload.gmb_location
                ? payload.gmb_location.id
                : null,
            },
          },
        },
      },
      ui: {
        ...state.ui,
        editLocationModal: {
          ...state.ui.editLocationModal,
          isOpen: false,
          isPending: false,
        },
      },
    };
  })
  .handleAction(actions.archiveLocationPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        deleteLocationModal: {
          ...state.ui.deleteLocationModal,
          isPending: true,
        },
      },
    };
  })
  .handleAction(
    actions.archiveLocationSuccess,
    (
      state,
      {
        meta: {
          originalRequest: { url },
        },
      }
    ) => {
      const locationId = parseInt(url.split("/").slice(-1)[0]);
      return {
        ...state,
        data: {
          ...state.data,
          locations: {
            ...state.data.locations,
            byId: {
              ...state.data.locations.byId,
              [locationId]: {
                ...state.data.locations.byId[locationId],
                archived: true,
              },
            },
          },
        },
        ui: {
          ...state.ui,
          deleteLocationModal: {
            ...state.ui.deleteLocationModal,
            isOpen: false,
            isPending: false,
          },
        },
      };
    }
  )
  // Create sender
  .handleAction(actions.createSenderPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      createSenderPending: true,
    },
  }))
  .handleAction(
    actions.createSenderSuccess,
    (
      state,
      {
        payload,
        meta: {
          originalRequest: {
            payload: { location_id },
          },
        },
      }
    ) => {
      if (state && state.data) {
        return {
          ...state,
          data: {
            ...state.data,
            locations: {
              ...state.data.locations,
              byId: {
                ...state.data.locations.byId,
                [location_id]: {
                  ...state.data.locations.byId[location_id],
                  sendinblue_sender_id: payload.id,
                },
              },
            },
          },
          ui: {
            ...state.ui,
            createSenderPending: false,
          },
        };
      }
      return state;
    }
  )
  .handleAction(actions.createSenderError, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      createSenderPending: false,
    },
  }));

export { reducer as locationsReducer };
