import { AppPage } from "./types";
import { createStandardAction } from "typesafe-actions";
import { requestActionCreator } from "../../actionCreators";
import { APIRequestConfig, IPaginated } from "../../types";

export const getAppPagesRequest = () => {
  return requestActionCreator("@appPages/getAppPages")({
    request: {
      url: "/app-pages",
      method: "GET",
      mock: {
        status: 200,
        body: [],
      },
    },
  });
};

export const getAppPagesPending = createStandardAction(
  "@appPages/getAppPagesPending"
)();
export const getAppPagesSuccess = createStandardAction(
  "@appPages/getAppPagesSuccess"
)<IPaginated<AppPage>, { originalRequest: APIRequestConfig }>();
