import { AccountState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";

export type AccountAction = ActionType<typeof actions>;

const initialState: AccountState = {
  isLoadingCache: true,
  cache: null,
  ui: {
    loginPending: false,
    signUpPending: false,
    changePending: false,
  },
  data: null,
  isLoading: false,
  gmb: {
    isLoading: false,
    authorization_url: null,
  },
  allCompanyPermissions: {
    data: {},
    ui: {
      isPending: false,
    },
  },
};

const reducer = createReducer(initialState)
  .handleAction(actions.loadCache, (state, action) => ({
    ...state,
    isLoadingCache: true,
  }))
  .handleAction(actions.loadCacheSuccess, (state, { payload }) => ({
    ...state,
    isLoadingCache: false,
    cache: {
      ...payload,
    },
  }))
  .handleAction(actions.loginPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      loginPending: true,
    },
  }))
  .handleAction(actions.signUpPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      signUpPending: true,
    },
  }))
  .handleAction(
    actions.loginSuccess,
    (state, { payload: { access, refresh } }) => {
      return {
        ...state,
        cache: {
          ...(state.cache || {}),
          token: access,
        },
        ui: {
          ...state.ui,
          loginPending: false,
        },
      };
    }
  )
  .handleAction(actions.loginError, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      loginPending: false,
    },
  }))
  .handleAction(actions.signUpSuccess, (state, { payload: { token } }) => ({
    ...state,
    cache: {
      ...(state.cache || {}),
      token,
    },
    ui: {
      ...state.ui,
      signUpPending: false,
    },
  }))
  .handleAction(actions.signUpError, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      signUpPending: false,
    },
  }))
  .handleAction([actions.logoutRequest, actions.notAuthorised], (state) => ({
    ...state,
    cache: null,
  }))
  .handleAction(actions.getAccountPending, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(actions.getAccountSuccess, (state, { payload }) => ({
    ...state,
    data: {
      ...payload,
    },
    isLoading: false,
  }))
  // Update account
  .handleAction(actions.updateAccountPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      changePending: true,
    },
  }))
  .handleAction(actions.updateAccountSuccess, (state, { payload }) => ({
    ...state,
    data: {
      ...payload,
    },
    ui: {
      ...state.ui,
      changePending: false,
    },
  }))
  // Email
  .handleAction(actions.updateEmailPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      changePending: true,
    },
  }))
  .handleAction(actions.updateEmailSuccess, (state, { payload }) => ({
    ...state,
    data: {
      ...payload,
    },
    ui: {
      ...state.ui,
      changePending: false,
    },
  }))
  .handleAction(actions.updateEmailError, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      changePending: false,
    },
  }))
  //
  .handleAction(actions.getGmbAuthLinkPending, (state) => ({
    ...state,
    gmb: {
      ...state.gmb,
      isLoading: true,
    },
  }))
  .handleAction(
    actions.getGmbAuthLinkSuccess,
    (state, { payload: { authorization_url } }) => ({
      ...state,
      gmb: {
        ...state.gmb,
        isLoading: false,
        authorization_url,
      },
    })
  )
  .handleAction(actions.getCompanyAccountPermissionsPending, (state) => ({
    ...state,
    allCompanyPermissions: {
      ...state.allCompanyPermissions,
      ui: {
        ...state.allCompanyPermissions.ui,
        isPending: true,
      },
    },
  }))
  .handleAction(
    actions.getCompanyAccountPermissionsSuccess,
    (state, { payload }) => ({
      ...state,
      allCompanyPermissions: {
        ...state.allCompanyPermissions,
        data: payload,
        ui: {
          ...state.allCompanyPermissions.ui,
          isPending: false,
        },
      },
    })
  )
  .handleAction(actions.getCompanyAccountPermissionsError, (state) => ({
    ...state,
    allCompanyPermissions: {
      ...state.allCompanyPermissions,
      ui: {
        ...state.allCompanyPermissions.ui,
        isPending: false,
      },
    },
  }));

export { reducer as accountReducer };
