import { createStandardAction } from "typesafe-actions";
import { requestActionCreator } from "../../actionCreators";
import { APIRequestConfig } from "../../types";
import { CreateReferralRequest, ReferralsGetResponse } from "./types";

export const getReferralsRequest = () => {
  return requestActionCreator("@referrals/getReferrals")({
    request: {
      url: "/referrals",
      method: "GET",
    },
  });
};

export const getReferralsPending = createStandardAction(
  "@referrals/getReferralsPending"
)();
export const getReferralsSuccess = createStandardAction(
  "@referrals/getReferralsSuccess"
)<ReferralsGetResponse[], { request: APIRequestConfig }>();

export const createReferralRequest = (payload: CreateReferralRequest) => {
  return requestActionCreator("@referrals/createReferral")({
    request: {
      url: "/referrals",
      method: "POST",
      payload,
    },
  });
};

export const createReferralPending = createStandardAction(
  "@referrals/createReferralPending"
)();
export const createReferralSuccess = createStandardAction(
  "@referrals/createReferralSuccess"
)<ReferralsGetResponse, { originalRequest: APIRequestConfig }>();

export const createReferralError = createStandardAction(
  "@referrals/createReferralError"
)();
