import { matchPath } from "react-router-dom";

export const parsePath = <ParamsObj extends { [key: string]: string }>(
  pathname: string,
  pattern: string
) => {
  const match = matchPath<ParamsObj>(pathname, {
    path: pattern,
  });
  return match?.params as ParamsObj;
};
