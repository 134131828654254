import { createSelector } from "reselect";
import { AppState } from "../../index";
import {
  ICustomSurvey,
  IGlobalCustomSurvey,
  ISupplementarySurvey,
  ISurveyState,
  SurveyMetrics,
  SurveyPageName,
} from "./types";
import { Selector } from "react-redux";
import { selectLocationsState } from "../locations/selectors";

export const selectSurveysState = (state: AppState): ISurveyState =>
  state.surveys;

export const selectCustomSurveys: Selector<
  AppState,
  ICustomSurvey[]
> = createSelector(selectSurveysState, ({ data: { surveys } }) =>
  Object.values(surveys.byId)
);

export const selectSupplementarySurveys: Selector<
  AppState,
  ISupplementarySurvey[]
> = createSelector(selectSurveysState, ({ data: { supplementarySurveys } }) =>
  Object.values(supplementarySurveys.byId)
);

export const selectGetSurveysPending: Selector<
  AppState,
  boolean
> = createSelector(
  selectSurveysState,
  ({ ui: { getSurveysPending } }) => getSurveysPending
);

export const selectCustomSurveysByLocation: Selector<
  AppState,
  { [key: number]: ICustomSurvey[] }
> = createSelector(
  selectSurveysState,
  selectLocationsState,
  ({ data: { surveys } }, { data: { locations } }) => {
    const allSurveys = Object.values(surveys.byId);
    const surveysByLocation: { [key: number]: ICustomSurvey[] } = {};
    for (const survey of allSurveys.filter((sur) => sur.state === "ACTIVE")) {
      for (const location of survey.locations) {
        if (!surveysByLocation[location]) {
          surveysByLocation[location] = [];
        }
        surveysByLocation[location].push(survey);
      }
    }
    for (const locationId of locations.ids) {
      if (!(locationId in surveysByLocation)) {
        surveysByLocation[locationId] = [];
      }
    }
    return surveysByLocation;
  }
);

export const selectCustomSurveyById: (
  id: number | null
) => Selector<AppState, ICustomSurvey | null> = (id) => {
  return createSelector(selectSurveysState, ({ data: { surveys } }) =>
    id ? surveys.byId[id] : null
  );
};

export const selectCustomerSurveyResponseId: () => Selector<
  AppState,
  number | null
> = () => {
  return createSelector(
    selectSurveysState,
    ({ data: { customerSurveyResponseId } }) => customerSurveyResponseId
  );
};

export const selectGlobalCustomSurveyById: (
  id: number | null
) => Selector<AppState, IGlobalCustomSurvey | null> = (id) => {
  return createSelector(selectSurveysState, ({ data: { globalSurveys } }) =>
    id ? globalSurveys.byId[id] : null
  );
};

export const selectCustomerSurvey: Selector<
  AppState,
  ICustomSurvey | null
> = createSelector(
  selectSurveysState,
  ({ data: { customerSurvey } }) => customerSurvey
);

export const selectHasLpData: Selector<AppState, boolean> = createSelector(
  selectSurveysState,
  ({ data: { hasLpData } }) => hasLpData
);

export const selectCreateSurveyPending: Selector<
  AppState,
  boolean
> = createSelector(
  selectSurveysState,
  ({ ui: { createSurveyPending } }) => createSurveyPending
);

export const selectGetCustomerSurveyPending: Selector<
  AppState,
  boolean
> = createSelector(
  selectSurveysState,
  ({ ui: { getCustomerSurveyPending } }) => getCustomerSurveyPending
);

export const selectSurveyCreatedModalIsOpen: Selector<
  AppState,
  boolean
> = createSelector(
  selectSurveysState,
  ({ ui: { createdModalOpen } }) => createdModalOpen
);

export const selectOpenSurveyOptions: Selector<
  AppState,
  number
> = createSelector(
  selectSurveysState,
  ({ ui: { surveyOptionsOpen } }) => surveyOptionsOpen
);

export const selectOpenSupplementarySurveyOptions: Selector<
  AppState,
  number
> = createSelector(
  selectSurveysState,
  ({ ui: { supplementarySurveyOptionsOpen } }) => supplementarySurveyOptionsOpen
);

export const selectCreateCustomSurveyResponsePending: Selector<
  AppState,
  boolean
> = createSelector(
  selectSurveysState,
  ({ ui: { createCustomSurveyResponsePending } }) =>
    createCustomSurveyResponsePending
);

export const selectSurveyPage: Selector<
  AppState,
  SurveyPageName
> = createSelector(selectSurveysState, ({ ui: { surveyPage } }) => surveyPage);

export const selectNewSurveyInitialData: Selector<
  AppState,
  any[] | null
> = createSelector(
  selectSurveysState,
  ({ data: { newSurveyInitialData } }) => newSurveyInitialData
);

export const selectSurveyRedirectUrl: Selector<
  AppState,
  string
> = createSelector(
  selectSurveysState,
  ({ data: { locationRedirectUrl } }) => locationRedirectUrl
);

export const selectCustomSurveyMetrics: Selector<
  AppState,
  SurveyMetrics
> = createSelector(
  selectSurveysState,
  ({ data: { surveyMetrics } }) => surveyMetrics
);

export const selectCustomSurveyStatisticsPending: Selector<
  AppState,
  boolean
> = createSelector(
  selectSurveysState,
  ({
    ui: {
      surveyMetrics: { getSurveyMetricsPending },
    },
  }) => getSurveyMetricsPending
);

export const selectSelectedSurveyForMetrics: Selector<
  AppState,
  number | null
> = createSelector(
  selectSurveysState,
  ({ data: { selectedSurveyForMetrics } }) => selectedSurveyForMetrics
);

export const selectUsingGlobalSurveyMetrics: Selector<
  AppState,
  boolean
> = createSelector(
  selectSurveysState,
  ({ data: { usingGlobalSurveyMetrics } }) => usingGlobalSurveyMetrics
);
