import { createSelector } from "reselect";
import { AppState } from "../../index";
import {
  Competitor,
  IAddLocationUiState,
  IDeleteLocationModal,
  IEditLocationModal,
  IGmbLocation,
  ILocation,
  ILocationNormalized,
  ILocationState,
  IPublicLocation,
  ISetGmbLocationModal,
} from "./types";
import { Selector } from "react-redux";
import { IPaginated } from "../../types";

export const selectLocationsState = (state: AppState): ILocationState =>
  state.locations;

export const selectLocationDetailById: (
  id: number | null
) => Selector<AppState, ILocation | null> = (id) =>
  createSelector(
    selectLocationsState,
    ({
      data: {
        locations: { byId },
        gmb_locations: { byId: gmbLocations },
      },
    }) =>
      id && byId[id]
        ? {
            ...byId[id],
            gmb_location: byId[id].gmb_location
              ? gmbLocations[byId[id].gmb_location!]
              : null,
          }
        : null
  );

export const selectAddLocationState: Selector<
  AppState,
  IAddLocationUiState
> = createSelector(
  selectLocationsState,
  ({ ui: { addLocation } }) => addLocation
);

export const selectSetGmbLocationState: Selector<
  AppState,
  ISetGmbLocationModal
> = createSelector(
  selectLocationsState,
  ({ ui: { setGmbLocationModal } }) => setGmbLocationModal
);

export const selectLocationPagination: Selector<
  AppState,
  IPaginated<any>["pagination"] | null
> = createSelector(
  selectLocationsState,
  ({
    ui: {
      locations: { pagination },
    },
  }) => pagination
);

export const selectLocationSummariesLoading: Selector<
  AppState,
  boolean
> = createSelector(
  selectLocationsState,
  ({
    ui: {
      locationSummaries: { isLoading },
    },
  }) => isLoading
);

export const selectEditLocationState: Selector<
  AppState,
  IEditLocationModal
> = createSelector(
  selectLocationsState,
  ({ ui: { editLocationModal } }) => editLocationModal
);

export const selectDeleteLocationState: Selector<
  AppState,
  IDeleteLocationModal
> = createSelector(
  selectLocationsState,
  ({ ui: { deleteLocationModal } }) => deleteLocationModal
);

export const selectLocation: (
  id: number
) => Selector<AppState, ILocation | null> = (id) =>
  createSelector(
    selectLocationsState,
    ({
      data: {
        locations: { byId },
        gmb_locations: { byId: gmbLocations },
      },
    }) =>
      byId[id]
        ? {
            ...byId[id],
            gmb_location: byId[id].gmb_location
              ? gmbLocations[byId[id].gmb_location!]
              : null,
          }
        : null
  );

export const selectGmbLocation: (
  id: number
) => Selector<AppState, IGmbLocation> = (id) =>
  createSelector(
    selectLocationsState,
    ({
      data: {
        gmb_locations: { byId },
      },
    }) => byId[id]
  );

export const selectLocationIdsInOrder: Selector<
  AppState,
  number[]
> = createSelector(
  selectLocationsState,
  ({
    data: {
      locations: { ids },
    },
  }) => ids
);

export const selectLocationsHasLoaded: Selector<
  AppState,
  boolean
> = createSelector(
  selectLocationsState,
  ({
    ui: {
      locations: { hasLoaded },
    },
  }) => hasLoaded
);

export const selectLocations: Selector<
  AppState,
  ILocationNormalized[]
> = createSelector(
  selectLocationsState,
  ({
    data: {
      locations: { ids, byId },
    },
  }) => ids.map((id) => byId[id])
);

export const selectLiveLocations: Selector<
  AppState,
  ILocationNormalized[]
> = createSelector(
  selectLocationsState,
  ({
    data: {
      locations: { ids, byId },
    },
  }) => ids.map((id) => byId[id]).filter((loc) => !loc.archived)
);

export const selectLocationsData: Selector<
  AppState,
  {
    ids: number[];
    byId: {
      [key: number]: ILocationNormalized;
    };
  }
> = createSelector(
  selectLocationsState,
  ({ data: { locations } }) => locations
);

export const selectLocationSummaries: Selector<
  AppState,
  ILocationNormalized[]
> = createSelector(
  selectLocationsState,
  ({
    data: {
      locationSummaries: { ids, byId },
    },
  }) => ids.map((id) => byId[id])
);

export const selectLocationSummaryNames: Selector<
  AppState,
  string[]
> = createSelector(selectLocationSummaries, (locations) =>
  locations.map(({ name }) => name)
);

export const selectGmbLocationIdsInOrder: Selector<
  AppState,
  number[]
> = createSelector(
  selectLocationsState,
  ({
    data: {
      gmb_locations: { ids, byId },
    },
  }) =>
    ids.sort((a, b) =>
      byId[a].location_name.toLowerCase() < byId[b].location_name.toLowerCase()
        ? -1
        : 1
    )
);

export const selectQrModalIsOpen: Selector<AppState, boolean> = createSelector(
  selectLocationsState,
  ({
    ui: {
      qrModal: { isOpen },
    },
  }) => isOpen
);

export const selectCreateSenderPending: Selector<
  AppState,
  boolean
> = createSelector(
  selectLocationsState,
  (locations) => locations.ui.createSenderPending
);

export const selectCustomerLocation: Selector<
  AppState,
  IPublicLocation | null
> = createSelector(
  selectLocationsState,
  ({ data: { customerLocation } }) => customerLocation
);

export const selectGetCustomerLocationPending: Selector<
  AppState,
  boolean
> = createSelector(
  selectLocationsState,
  ({ ui: { getCustomerLocationPending } }) => getCustomerLocationPending
);

export const selectCompetitors: Selector<
  AppState,
  Competitor[]
> = createSelector(
  selectLocationsState,
  ({ data: { competitors } }) => competitors
);

export const selectSearchedLocation: Selector<
  AppState,
  Competitor | null
> = createSelector(
  selectLocationsState,
  ({ data: { searchedLocation } }) => searchedLocation
);
