import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import {
  selectIsLoadingCache,
  selectIsLoggedIn,
} from "../../store/slices/account/selectors";
import FullScreenLoading from "../molecules/FullScreenLoading";

const DefaultSuspense = ({ children }: { children: JSX.Element }) => {
  return <Suspense fallback={<FullScreenLoading />}>{children}</Suspense>;
};

const Unauthorised = React.lazy(() => import("./Unauthorised"));
const Authorised = React.lazy(() => import("./Authorised"));

const AuthCheck = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isLoadingCache = useSelector(selectIsLoadingCache);

  if (isLoadingCache) {
    return <FullScreenLoading />;
  }

  return isLoggedIn ? (
    <DefaultSuspense>
      <Authorised />
    </DefaultSuspense>
  ) : (
    <DefaultSuspense>
      <Unauthorised />
    </DefaultSuspense>
  );
};

export default AuthCheck;
