import { createStandardAction } from "typesafe-actions";
import { requestActionCreator } from "../../actionCreators";
import { APIRequestConfig } from "../../types";

export const getJobTypesRequest = () => {
  return requestActionCreator("@jobTypes/getJobTypes")({
    request: {
      url: "/job_types",
      method: "GET",
    },
  });
};

export const getJobTypesPending = createStandardAction(
  "@jobTypes/getJobTypesPending"
)();
export const getJobTypesSuccess = createStandardAction(
  "@jobTypes/getJobTypesSuccess"
)<any, { request: APIRequestConfig }>();
