import { DependencyList, MutableRefObject, useEffect } from "react";

export const useOutsideClickEvents = (
  refs: MutableRefObject<any>[],
  callback: () => void,
  deps: DependencyList,
  ignoredClasses: string[] = [],
  disabled: boolean = false
) => {
  useEffect(() => {
    const handleClick = (event: Event) => {
      if (disabled) {
        return;
      }
      let wasOutside = true;
      const clickedClasses = (event.target as any)?.classList;

      for (let i = 0; i < refs.length; i++) {
        if (
          !refs[i].current ||
          (refs[i].current &&
            (refs[i].current.contains(event.target) ||
              ignoredClasses.some((className) =>
                clickedClasses?.contains(className)
              )))
        ) {
          wasOutside = false;
          break;
        }
      }
      if (wasOutside) {
        callback();
      }
    };
    // bind the event listener
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, [...refs, ...deps]);
};
