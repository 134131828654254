import { StatisticsState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";
import { parsePath } from "../../../functions/parsePath";

export type StatisticsAction = ActionType<typeof actions>;

const initialState: StatisticsState = {
  data: {
    statistics: {
      byLocation: {},
    },
  },
  ui: {
    statistics: {
      isLoading: false,
    },
  },
};

const reducer = createReducer(initialState)
  .handleAction(actions.getStatisticsPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      statistics: {
        ...state.ui.statistics,
        isLoading: true,
      },
    },
  }))
  // .handleAction(
  //   actions.getStatisticsSuccess,
  //   (
  //     state,
  //     {
  //       payload: ,
  //       meta: {
  //         originalRequest: { url, params },
  //       },
  //     }
  //   ) => {
  //     const locationId = parseInt(
  //       parsePath<{ locationId: string }>(url, "/gmb/reviews/:locationId")
  //         .locationId
  //     );
  //     const pageToken = params?.page_token;
  //     return {
  //       ...state,
  //       data: {
  //         ...state.data,
  //         statistics: {
  //           ...state.data.statistics,
  //           byLocation: {
  //             ...state.data.statistics.byLocation,
  //             [locationId]: {
  //               averageRating,
  //               totalReviewCount,
  //               nextPageToken,
  //             },
  //           },
  //         },
  //       },
  //       ui: {
  //         ...state.ui,
  //         statistics: {
  //           ...state.ui.statistics,
  //           isLoading: false,
  //         },
  //       },
  //     };
  //   }
  // )
  .handleAction(actions.getAllStatisticsPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      statistics: {
        ...state.ui.statistics,
        isLoading: true,
      },
    },
  }))
  .handleAction(
    actions.getAllStatisticsSuccess,
    (
      state,
      {
        payload: allReviews,
        meta: {
          originalRequest: { url, params },
        },
      }
    ) => {
      delete allReviews["end_time"];
      delete allReviews["start_time"];
      return {
        ...state,
        data: {
          ...state.data,
          statistics: {
            ...state.data.statistics,
            byLocation: {
              ...state.data.statistics.byLocation,
              ...allReviews,
            },
          },
        },
        ui: {
          ...state.ui,
          statistics: {
            ...state.ui.statistics,
            isLoading: false,
          },
        },
      };
    }
  );

export { reducer as statisticsReducer };
