import { DependencyList, useEffect } from "react";

export const useKeyDownEvents = (
  keyCodes: number[],
  callback: () => void,
  deps: DependencyList
) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (keyCodes.indexOf(event.keyCode) > -1) {
        callback();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line
  }, [...deps]);
};
