import { Middleware } from "redux";
import { push } from "connected-react-router";
import * as customerActions from "../slices/customers/actions";
import * as accountActions from "../slices/account/actions";
import * as locationActions from "../slices/locations/actions";
import * as surveyActions from "../slices/surveys/actions";
import * as reviewActions from "../slices/reviews/actions";
import * as referralActions from "../slices/referrals/actions";
import { RootAction } from "../actions";
import { getType, PayloadAction } from "typesafe-actions";
import { toast } from "react-toastify";
import i18n from "../../i18n";

export const notificationsMiddleware: Middleware = (store) => (next) => (
  action: RootAction
) => {
  next(action);

  switch (action.type) {
    case getType(accountActions.loginError):
      toast.error(i18n.t("views.login.notifications.loginError"));
      return;

    case getType(accountActions.signUpError): {
      const {
        payload: { non_field_errors, referral_code },
      } = action as PayloadAction<any, any>;

      if (referral_code) {
        if (
          referral_code.code &&
          referral_code.code === "referral_does_not_exist"
        ) {
          toast.error(i18n.t("views.signUp.notifications.noMatchingUUID"));
        } else if (
          referral_code.code &&
          referral_code.code === "referral_used"
        ) {
          toast.error(i18n.t("views.signUp.notifications.referralUsed"));
        } else {
          toast.error(i18n.t("views.signUp.notifications.invalidUUID"));
        }
      } else if (
        non_field_errors &&
        non_field_errors.includes("email_address_taken")
      ) {
        toast.error(i18n.t("views.signUp.notifications.emailAddressTaken"));
      } else {
        toast.error(i18n.t("views.signUp.notifications.signUpError"));
      }
      return;
    }

    case getType(accountActions.updateAccountSuccess):
      toast.success(i18n.t("views.account.notification.accountUpdated"));
      store.dispatch(push("/account"));
      return;

    case getType(customerActions.chunkedUploadComplete):
      toast.success(i18n.t("views.customers.notifications.bulkSuccess"));
      return;

    case getType(customerActions.saveCustomerCSVDataError):
      toast.error(i18n.t("views.customers.notifications.bulkError"));
      return;

    case getType(customerActions.addCustomerSuccess):
      toast.success(i18n.t("views.customers.notifications.singleSuccess"));
      return;

    case getType(customerActions.addCustomerError):
      const { payload } = action as PayloadAction<any, any>;
      if (
        payload &&
        payload.detail &&
        payload.detail === "duplicate_customer"
      ) {
        toast.error(
          i18n.t("views.customers.notifications.duplicateCustomerError")
        );
      } else {
        toast.error(i18n.t("views.customers.notifications.singleError"));
      }
      return;

    case getType(locationActions.patchLocationSuccess): {
      toast.success(
        i18n.t("views.customers.notifications.patchLocationSuccess")
      );
      return;
    }

    case getType(locationActions.deleteLocationSuccess): {
      toast.success(
        i18n.t("views.customers.notifications.deleteLocationSuccess")
      );
      return;
    }

    case getType(locationActions.patchLocationError): {
      toast.error(i18n.t("views.locations.notifications.patchLocationError"));
      return;
    }

    case getType(locationActions.createSenderError):
      toast.error(
        i18n.t(
          "views.locations.createSenderModal.notifications.createSenderError"
        )
      );
      return;

    case getType(accountActions.updateEmailSuccess):
      toast.success(i18n.t("views.account.notification.accountUpdated"));
      return;

    case getType(accountActions.updateEmailError):
      toast.error(i18n.t("views.account.notification.passwordError"));
      return;

    case getType(surveyActions.createSurveyError):
      toast.error(i18n.t("views.surveys.notification.createSurveyError"));
      return;

    case getType(surveyActions.archiveSurveySuccess):
      toast.success(i18n.t("views.surveys.notification.archiveSurveySuccess"));
      return;

    case getType(surveyActions.createCustomSurveyResponseError):
      toast.error(
        i18n.t("views.surveys.notification.createCustomSurveyResponseError")
      );
      return;

    case getType(surveyActions.updateCustomSurveyResponseError):
      toast.error(
        i18n.t("views.surveys.notification.updateCustomSurveyResponseError")
      );
      return;

    case getType(surveyActions.unarchiveSurveySuccess):
      toast.success(
        i18n.t("views.surveys.notification.unarchiveSurveySuccess")
      );
      return;

    case getType(reviewActions.updateGmbReviewReplySuccess):
      toast.success(i18n.t("views.reviews.notifications.updateReplySuccess"));
      return;

    case getType(reviewActions.updateGmbReviewReplyError):
      toast.error(i18n.t("views.reviews.notifications.updateReplyError"));
      return;

    case getType(referralActions.createReferralError):
      toast.error(i18n.t("views.referrals.notifications.createReferralError"));
      return;

    case getType(referralActions.createReferralSuccess):
      toast.success(
        i18n.t("views.referrals.notifications.createReferralSuccess")
      );
      return;

    default:
      return;
  }
};
