import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

import { useTranslation } from "react-i18next";
import Btn from "../atoms/Btn";
import { sendPlaceId } from "../../store/slices/locations/actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { validateEmail } from "../../functions/validateCustomerCSV";
import Modal from "../molecules/Modal";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import TargetGradeInput from "../molecules/TargetGradeInput";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const center = { lat: 48.8647, lng: 2.349 };
const libraries: any = ["places"];

type Props = {
  defaultPlaceId?: string;
  disabled?: boolean;
};

const LandingPageMap = ({ defaultPlaceId, disabled }: Props) => {
  const { t } = useTranslation();
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [place, setPlace] = useState<any>(null);
  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<any>(null);
  const [showInfoBox, setShowInfoBox] = useState<boolean>(false);
  const [showThankYouBox, setShowThankYouBox] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [targetGrade, setTargetGrade] = useState<number | null>(null);
  const dispatch = useDispatch();
  const { search } = useLocation();

  const {
    source,
    targetgrade: targetGradeParam,
    partner: partnerParam,
  } = parse(search) as {
    source: string | undefined;
    targetgrade: string | undefined;
    partner: string | undefined;
  };
  const requireTargetGrade = !!targetGradeParam;
  const requirePartnerFields = !!partnerParam;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY!,
    libraries,
  });

  useEffect(() => {
    if (isLoaded && defaultPlaceId) {
      geocodeByPlaceId(defaultPlaceId)
        .then((results) => setPlace(results[0]))
        .catch((error) => console.error(error));
    }
  }, [defaultPlaceId, isLoaded]);

  useEffect(() => {
    if (place) {
      map.panTo(place.geometry.location);
      map.setZoom(9);
    }
  }, [place]);

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(4);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const onLoadAutoComplete = React.useCallback((autoc) => {
    setAutocomplete(autoc);
  }, []);

  const onPlaceChanged = React.useCallback(() => {
    if (autocomplete !== null) {
      const chosenPlace = autocomplete.getPlace();
      if (
        chosenPlace &&
        chosenPlace.geometry &&
        chosenPlace.geometry.location
      ) {
        setPlace(chosenPlace);
        setShowInfoBox(true);
      }
    }
  }, [map, autocomplete, place]);

  const onPlaceConfirm = () => {
    dispatch(
      sendPlaceId({
        placeId: place.place_id,
        placeName: place.name,
        placeAddress: place.formatted_address,
        placeType: JSON.stringify(place.types),
        source: source || "",
        targetGrade: targetGrade || 0,
        phoneNumber: phoneNumber || "",
        timestamp: moment(new Date()).format(),
        email,
      })
    );
    setShowInfoBox(false);
    setShowThankYouBox(true);
    setEmail("");
    setTargetGrade(null);
  };

  return (
    <div
      className="tw-rounded-md tw-border-4 tw-border-white tw-custom-shadow tw-mb-6 tw-relative"
      style={{ height: "100%" }}
    >
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={center}
          zoom={4}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
          }}
        >
          {!disabled && (
            <Autocomplete
              onLoad={onLoadAutoComplete}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                placeholder={t(
                  "views.locations.addLocationModal.locationPlaceholder"
                )}
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `200px`,
                  height: `32px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: "absolute",
                  left: "50%",
                  top: "10px",
                  transform: "translateX(-50%)",
                }}
              />
            </Autocomplete>
          )}
          {place && (
            <Marker
              position={place.geometry.location}
              onLoad={(marker) => {
                setMarker(marker);
              }}
            >
              {marker && !disabled && showInfoBox && (
                <InfoWindow
                  anchor={marker}
                  options={{
                    minWidth: 240,
                  }}
                  onCloseClick={() => {
                    setShowInfoBox(false);
                  }}
                >
                  <div className="tw-flex tw-flex-col sm:tw-px-12 sm:tw-py-4">
                    <div className="tw-text-xl tw-py-4 tw-mb-4 tw-border-b-2 tw-border-divider tw-text-center">
                      {t("shared.yourLocation")}
                    </div>
                    <div className="sm:tw-text-lg tw-font-bold tw-mb-2">
                      {t("shared.name")}
                    </div>
                    <div className="sm:tw-text-lg tw-mb-4">{place.name}</div>
                    <div className="sm:tw-text-lg tw-font-bold tw-mb-2">
                      {t("shared.address")}
                    </div>
                    <div className="sm:tw-text-lg tw-mb-4">
                      {place.formatted_address}
                    </div>
                    <div className="tw-text-md tw-font-bold tw-mb-2">
                      {t("components.landingPageMap.emailBlurb")}
                    </div>
                    <div className="tw-flex tw-flex-col">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        placeholder={t("shared.email")}
                        className="tw-text-medium tw-px-4 tw-py-2 tw-rounded-xl tw-custom-shadow tw-w-full tw-border tw-border-divider tw-mr-4 tw-mb-2"
                      />
                      {requirePartnerFields && (
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phoneNumber}
                          onChange={(number) =>
                            setPhoneNumber(number ? number?.toString() : "")
                          }
                          className="tw-text-medium tw-px-4 tw-py-2 tw-rounded-xl tw-custom-shadow tw-w-full tw-border tw-border-divider tw-mr-4 tw-mb-2"
                          defaultCountry="FR"
                        />
                      )}
                      {requireTargetGrade && (
                        <TargetGradeInput
                          value={targetGrade}
                          onChange={(value) => setTargetGrade(value)}
                        />
                      )}
                      <Btn
                        onClick={() => {
                          onPlaceConfirm();
                        }}
                        className={
                          "tw-bg-primary tw-text-white tw-font-semibold tw-text-sm !tw-px-4 !tw-py-2"
                        }
                        isDisabled={
                          !validateEmail(email) ||
                          (requireTargetGrade && !targetGrade) ||
                          (requirePartnerFields && !phoneNumber)
                        }
                      >
                        {t("shared.select")}
                      </Btn>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          )}
        </GoogleMap>
      ) : (
        <></>
      )}
      <Modal
        isShowing={showThankYouBox}
        onDismiss={() => {
          setShowThankYouBox(false);
        }}
      >
        <div className="tw-px-12 tw-py-6">
          <div className="tw-text-center tw-text-sm tw-font-semibold tw-mb-10">
            <button
              className="tw-h-7 tw-w-7 tw-rounded-full tw-custom-shadow tw-border tw-border-divider tw-absolute tw-right-0 tw-mr-6 tw-top-0 tw-mt-5 tw-flex tw-items-center tw-justify-center"
              onClick={() => setShowThankYouBox(false)}
            >
              x
            </button>
          </div>
          {t("components.landingPageMap.thankYouModal.blurb")}
        </div>
        <div className="tw-flex tw-flex-row tw-justify-center tw-pb-6">
          <Btn
            className={
              "tw-bg-primary tw-text-white tw-font-semibold tw-text-sm !tw-px-4 !tw-py-2"
            }
            onClick={() => setShowThankYouBox(false)}
          >
            Close
          </Btn>
        </div>
      </Modal>
    </div>
  );
};

export default LandingPageMap;
