import React from "react";
import Spinner from "../atoms/Spinner";

const FullScreenLoading = () => (
  <div className="tw-bg-qualiGray tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
    <Spinner />
  </div>
);

export default FullScreenLoading;
