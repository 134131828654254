import { AppPagesState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";

export type AppPagesAction = ActionType<typeof actions>;

const initialState: AppPagesState = {
  data: null,
  isLoading: false,
};

const reducer = createReducer(initialState)
  .handleAction(actions.getAppPagesPending, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(
    actions.getAppPagesSuccess,
    (state, { payload: { results } }) => ({
      ...state,
      data: [...results],
      isLoading: false,
    })
  );
export { reducer as appPagesReducer };
