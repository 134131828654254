import { SendinblueSender, SendinblueState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";

export type SendinblueAction = ActionType<typeof actions>;

const initialState: SendinblueState = {
  data: {
    senders: [],
  },
  ui: {
    loadingSenders: false,
  },
};

const reducer = createReducer(initialState)
  .handleAction(
    actions.getSendinblueSendersSuccess,
    (state, { payload }: { payload: { senders: SendinblueSender[] } }) => {
      if (payload) {
        return {
          ...state,
          data: {
            ...state.data,
            senders: payload.senders,
          },
          ui: {
            ...state.ui,
            loadingSenders: false,
          },
        };
      }
      return state;
    }
  )
  .handleAction(actions.getSendinblueSendersPending, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        loadingSenders: true,
      },
    };
  });

export { reducer as sendinblueReducer };
