import React from "react";
import { useTranslation } from "react-i18next";

const styles = {
  light: {
    text: "text-white",
  },
  dark: {
    text: "text-gray-700",
  },
};

interface ISpinnerProps {
  theme?: keyof typeof styles;
  className?: string;
}

const Spinner = ({ theme = "light", className }: ISpinnerProps) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles[theme].text} ${className || ""}`}>
      {t("shared.loadingState")}
    </div>
  );
};

export default Spinner;
