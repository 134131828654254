import { Selector } from "react-redux";
import { AppState } from "../../index";
import { createSelector } from "reselect";
import { DomainConfig } from "./types";
import { parseDomainName } from "../../../utilities/domainNames";

export const selectWhiteLabellingState = (state: AppState): any =>
  state.whiteLabelling;

export const selectDomainConfigByDomainName: (
  domainName: string
) => Selector<AppState, DomainConfig | null> = (domainName) => {
  return createSelector(
    selectWhiteLabellingState,
    ({
      data: {
        domainConfigs: { byDomain },
      },
    }) => domainName && byDomain[parseDomainName(domainName)]
  );
};

export const selectGotDomainConfig = createSelector(
  selectWhiteLabellingState,
  ({ data: { gotDomainConfig } }) => gotDomainConfig
);
