import React from "react";
import { Switch } from "@headlessui/react";

type Props = {
  checked: boolean;
  onChange(checked: boolean): void;
  height?: number;
  width?: number;
  toggleDims?: number;
  translateAmount?: number;
  disabled?: boolean;
};
export default function ToggleSwitch({
  checked,
  onChange,
  width,
  height,
  toggleDims,
  translateAmount,
  disabled,
}: Props) {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={`
        ${
          checked ? "tw-bg-primary" : "tw-bg-divider"
        } tw-relative tw-inline-flex
        tw-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-2 tw-border-transparent
        tw-transition-colors tw-duration-200 tw-ease-in-out focus:tw-outline-none focus-visible:tw-ring-2
        focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 tw-h-${
          height || 9
        } tw-w-${width || 16}
      `}
    >
      <span className="tw-sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`
          ${
            checked
              ? `tw-translate-x-${translateAmount || 7}`
              : "tw-translate-x-0"
          } tw-pointer-events-none tw-inline-block
          tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200
          tw-ease-in-out tw-h-${toggleDims || 8} tw-w-${toggleDims || 8}
        `}
      />
    </Switch>
  );
}
