import { Middleware } from "redux";
import * as actions from "../slices/customers/actions";
import {
  chunkedUploadComplete,
  saveCustomerCSVData,
  saveNextCSVChunk,
} from "../slices/customers/actions";
import { RootAction } from "../actions";
import { getType } from "typesafe-actions";
import { AppState } from "../index";
import { AddCustomerPayload } from "../slices/customers/types";

export const CHUNK_SIZE = 100;

export const chunkedUploadMiddleware: Middleware = (store) => (next) => (
  action: RootAction
) => {
  next(action);

  switch (action.type) {
    case getType(actions.saveNextCSVChunk):
      // the reducer has already added the next batch to inProgress
      const {
        customers: {
          ui: {
            uploadCSVModal: {
              chunkedRequest: { inProgress },
              bulkUploadId,
              locationToSurveyMap,
            },
          },
        },
        locations: {
          data: {
            locationSummaries: { byId },
          },
        },
      } = store.getState() as AppState;

      // This is a bit hacky, but we now need to send location ids instead of names and it was quickest to add
      // this here, rather than restructuring everything.
      const locationNameMap: { [key: string]: number } = Object.values(
        byId
      ).reduce(
        (prev, next) => ({
          ...prev,
          [next.name]: next.id,
        }),
        {}
      );

      const withLocationIds: AddCustomerPayload[] = inProgress
        .map(({ location_name, email_address, phone_number }) => {
          const locationId = location_name
            ? locationNameMap[location_name]
            : null;
          const surveyId = locationId
            ? locationToSurveyMap[locationId] || null
            : null;

          return {
            email_address,
            phone_number,
            location: locationId,
            custom_survey: surveyId,
          };
        })
        .filter((customer) => !!customer.location);

      // dispatch a save action with the next batch
      store.dispatch(saveCustomerCSVData(withLocationIds, bulkUploadId));
      return;

    case getType(actions.saveCustomerCSVDataSuccess):
      // reducer has already moved in progress to completed
      const {
        customers: {
          ui: {
            uploadCSVModal: {
              chunkedRequest: { toUpload },
            },
          },
        },
      } = store.getState();
      // dispatch a save action with the next batch or to complete the operation
      if (toUpload.length) {
        // this is a temporary hack: the google sheet can't handle lots of requests in quick succession
        // to ensure all data is saved, pause between each upload
        setTimeout(() => {
          store.dispatch(saveNextCSVChunk());
        }, 3000);
      } else {
        store.dispatch(chunkedUploadComplete());
      }
      return;

    default:
      return;
  }
};
