import { createStandardAction } from "typesafe-actions";
import {
  CreateReviewTemplateRequestPayload,
  IGmbReviewAllResponse,
  IGmbReviewReply,
  IGmbReviewResponse,
  IReviewTemplate,
} from "./types";
import { requestActionCreator } from "../../actionCreators";
import { APIRequestConfig, IPaginated } from "../../types";

export const getGmbReviewsRequest = (
  gmbLocationId: number,
  pageToken?: string,
  startDate?: string,
  endDate?: string
) => {
  return requestActionCreator("@reviews/getGmbReviews")({
    request: {
      url: `/gmb/reviews/${gmbLocationId}`,
      method: "GET",
      params: {
        ...(pageToken ? { page_token: pageToken } : {}),
        ...(startDate &&
          endDate && {
            start_time: startDate,
            end_time: endDate,
          }),
      },
    },
  });
};

export const getGmbReviewsPending = createStandardAction(
  "@reviews/getGmbReviewsPending"
)();
export const getGmbReviewsSuccess = createStandardAction(
  "@reviews/getGmbReviewsSuccess"
)<IGmbReviewResponse, { originalRequest: APIRequestConfig }>();
export const getGmbReviewsError = createStandardAction(
  "@reviews/getGmbReviewsError"
)();

export const getAllGmbReviewsRequest = () => {
  return requestActionCreator("@reviews/getAllGmbReviews")({
    request: {
      url: "/gmb/reviews",
      method: "GET",
    },
  });
};

export const getAllGmbReviewsPending = createStandardAction(
  "@reviews/getAllGmbReviewsPending"
)();
export const getAllGmbReviewsSuccess = createStandardAction(
  "@reviews/getAllGmbReviewsSuccess"
)<IGmbReviewAllResponse, { originalRequest: APIRequestConfig }>();
export const getAllGmbReviewsError = createStandardAction(
  "@reviews/getAllGmbReviewsError"
)();

export const updateGmbReviewReplyRequest = (
  gmbLocationId: number,
  gmbReviewName: string,
  comment: string
) => {
  return requestActionCreator("@reviews/updateGmbReviewReply")({
    request: {
      url: "/gmb/reviews",
      method: "POST",
      payload: {
        name: gmbReviewName,
        gmbLocationId, // Not used in the route but we need this in the reducer
        comment,
      },
    },
  });
};

export const updateGmbReviewReplyPending = createStandardAction(
  "@reviews/updateGmbReviewReplyPending"
)<undefined, { originalRequest: APIRequestConfig }>();

export const updateGmbReviewReplySuccess = createStandardAction(
  "@reviews/updateGmbReviewReplySuccess"
)<IGmbReviewReply, { originalRequest: APIRequestConfig }>();

export const updateGmbReviewReplyError = createStandardAction(
  "@reviews/updateGmbReviewReplyError"
)();

export const deleteGmbReviewReplyRequest = (
  gmbLocationId: number,
  gmbReviewName: string
) => {
  return requestActionCreator("@reviews/deleteGmbReviewReply")({
    request: {
      url: "/gmb/reviews",
      method: "POST",
      payload: {
        name: gmbReviewName,
      },
    },
  });
};

export const deleteGmbReviewReplyPending = createStandardAction(
  "@reviews/deleteGmbReviewReplyPending"
)<undefined, { originalRequest: APIRequestConfig }>();

export const deleteGmbReviewReplySuccess = createStandardAction(
  "@reviews/deleteGmbReviewReplySuccess"
)<null, { originalRequest: APIRequestConfig }>();

export const getReviewTemplatesRequest = () => {
  return requestActionCreator("@reviews/getReviewTemplates")({
    request: {
      url: `/review-templates`,
      method: "GET",
    },
  });
};

export const getReviewTemplatesPending = createStandardAction(
  "@reviews/getReviewTemplatesPending"
)();
export const getReviewTemplatesSuccess = createStandardAction(
  "@reviews/getReviewTemplatesSuccess"
)<IPaginated<IReviewTemplate>, { originalRequest: APIRequestConfig }>();

export const createReviewTemplateRequest = (
  body: CreateReviewTemplateRequestPayload
) => {
  return requestActionCreator("@reviews/createReviewTemplate")({
    request: {
      url: `/review-templates`,
      method: "POST",
      payload: body,
    },
  });
};

export const createReviewTemplatePending = createStandardAction(
  "@reviews/createReviewTemplatePending"
)<undefined, { originalRequest: APIRequestConfig }>();

export const createReviewTemplateSuccess = createStandardAction(
  "@reviews/createReviewTemplateSuccess"
)<IReviewTemplate, { originalRequest: APIRequestConfig }>();

export const updateReviewTemplateRequest = (
  body: { id: number } & Partial<CreateReviewTemplateRequestPayload>
) => {
  return requestActionCreator("@reviews/updateReviewTemplate")({
    request: {
      url: `/review-templates/${body.id}`,
      method: "PUT",
      payload: body,
    },
  });
};

export const updateReviewTemplatePending = createStandardAction(
  "@reviews/updateReviewTemplatePending"
)<undefined, { originalRequest: APIRequestConfig }>();

export const updateReviewTemplateSuccess = createStandardAction(
  "@reviews/updateReviewTemplateSuccess"
)<IReviewTemplate, { originalRequest: APIRequestConfig }>();

export const deleteReviewTemplateRequest = (id: number) => {
  return requestActionCreator("@reviews/deleteReviewTemplate")({
    request: {
      url: `/review-templates/${id}`,
      method: "DELETE",
    },
  });
};

export const deleteReviewTemplatePending = createStandardAction(
  "@reviews/deleteReviewTemplatePending"
)<undefined, { originalRequest: APIRequestConfig }>();

export const deleteReviewTemplateSuccess = createStandardAction(
  "@reviews/deleteReviewTemplateSuccess"
)<IReviewTemplate, { originalRequest: APIRequestConfig }>();
