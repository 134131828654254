import {
  AccountCache,
  AccountGetResponse,
  CompanyAccountPermissions,
  IGetGmbAuthLinkResponse,
  LoginRequestPayload,
  LoginSuccessResponse,
  SignUpRequestPayload,
  SignUpSuccessResponse,
  UpdateAccountRequestPayload,
  UpdateEmailRequestPayload,
} from "./types";
import { createStandardAction } from "typesafe-actions";
import { requestActionCreator } from "../../actionCreators";
import { APIRequestConfig } from "../../types";

export const loadCache = createStandardAction("@account/loadCache")();

export const loadCacheSuccess = createStandardAction(
  "@account/loadCacheSuccess"
)<AccountCache>();

export const notAuthorised = createStandardAction("@account/notAuthorised")();

export const loginRequest = (payload: LoginRequestPayload) => {
  return requestActionCreator("@account/login")({
    request: {
      url: "/accounts/auth",
      method: "POST",
      authenticated: false,
      mock: {
        status: 200,
        body: {
          access: "__FAKE_ACCESS_TOKEN__",
          refresh: "__FAKE_REFRESH_TOKEN__",
        },
      },
      payload,
    },
  });
};

export const loginPending = createStandardAction("@account/loginPending")();
export const loginSuccess = createStandardAction("@account/loginSuccess")<
  LoginSuccessResponse,
  { originalRequest: APIRequestConfig }
>();
export const loginError = createStandardAction("@account/loginError")();

export const signUpRequest = ({
  password,
  email,
  name,
  referralCode,
  termsAccepted,
}: SignUpRequestPayload) => {
  const body: { [key: string]: any } = {
    email,
    password,
    terms_accepted: termsAccepted,
    company: {
      name,
      signup_hostname: window.location.hostname,
    },
  };
  if (referralCode) {
    body.referral_code = referralCode;
  }
  return requestActionCreator("@account/signUp")({
    request: {
      url: "/accounts",
      method: "POST",
      authenticated: false,
      mock: {
        status: 200,
        body: {
          token: "__FAKE_TOKEN__",
        },
      },
      payload: body,
    },
  });
};

export const signUpPending = createStandardAction("@account/signUpPending")();
export const signUpSuccess = createStandardAction("@account/signUpSuccess")<
  SignUpSuccessResponse,
  { originalRequest: APIRequestConfig }
>();
export const signUpError = createStandardAction("@account/signUpError")();

export const logoutRequest = createStandardAction("@account/logout")();

export const getAccountRequest = () => {
  return requestActionCreator("@account/getAccount")({
    request: {
      url: "/accounts",
      method: "GET",
      mock: {
        status: 200,
        body: {
          id: 123,
          email: "test@email.com",
          is_verified: true,
        },
      },
    },
  });
};

export const getAccountPending = createStandardAction(
  "@account/getAccountPending"
)();
export const getAccountSuccess = createStandardAction(
  "@account/getAccountSuccess"
)<AccountGetResponse, { originalRequest: APIRequestConfig }>();

// Update account
export const updateAccountRequest = (payload: UpdateAccountRequestPayload) => {
  return requestActionCreator("@account/updateAccount")({
    request: {
      url: `/accounts/${payload.id}`,
      method: "PATCH",
      payload: {
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        // password: payload.password,
      },
    },
  });
};

export const updateAccountPending = createStandardAction(
  "@account/updateAccountPending"
)();
export const updateAccountSuccess = createStandardAction(
  "@account/updateAccountSuccess"
)<AccountGetResponse, { originalRequest: APIRequestConfig }>();

// Update email
export const updateEmailRequest = (payload: UpdateEmailRequestPayload) => {
  return requestActionCreator("@account/updateEmail")({
    request: {
      url: `/accounts/${payload.id}`,
      method: "PATCH",
      payload: {
        validation_password: payload.password,
        email: payload.email,
      },
    },
  });
};

export const updateEmailPending = createStandardAction(
  "@account/updateEmailPending"
)();
export const updateEmailSuccess = createStandardAction(
  "@account/updateEmailSuccess"
)<AccountGetResponse, { originalRequest: APIRequestConfig }>();

export const updateEmailError = createStandardAction(
  "@account/updateEmailError"
)();

export const getGmbAuthLinkRequest = () => {
  return requestActionCreator("@account/getGmbAuthLink")({
    request: {
      url: "/gmb/get-auth-link",
      method: "GET",
    },
  });
};

export const getGmbAuthLinkPending = createStandardAction(
  "@account/getGmbAuthLinkPending"
)();
export const getGmbAuthLinkSuccess = createStandardAction(
  "@account/getGmbAuthLinkSuccess"
)<IGetGmbAuthLinkResponse, { originalRequest: APIRequestConfig }>();

export const getCompanyAccountPermissionsRequest = () => {
  return requestActionCreator("@account/getCompanyAccountPermissions")({
    request: {
      url: "/accounts/get_all_user_permissions",
      method: "GET",
    },
  });
};
export const getCompanyAccountPermissionsPending = createStandardAction(
  "@account/getCompanyAccountPermissionsPending"
)();
export const getCompanyAccountPermissionsSuccess = createStandardAction(
  "@account/getCompanyAccountPermissionsSuccess"
)<CompanyAccountPermissions, { originalRequest: APIRequestConfig }>();
export const getCompanyAccountPermissionsError = createStandardAction(
  "@account/getCompanyAccountPermissionsError"
)();
