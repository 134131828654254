import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import { cleanCSVData } from "./cleanCSVData";

const phoneUtil = PhoneNumberUtil.getInstance();

// returns the index of all rows with no value for any of the jointly required headers
// assumes the first row is the header row
export const validateCustomerCSV: (
  data: string[][],
  jointlyRequiredHeaders: string[]
) => number[] = (data, jointlyRequiredHeaders) => {
  const requiredIndexes: number[] = jointlyRequiredHeaders.map((header) =>
    data[0].indexOf(header)
  );
  const incompleteRows: number[] = [];
  data.forEach((row, rowIndex) => {
    const jointValues = requiredIndexes
      .map((ind) => row[ind])
      .filter((val) => !!val);
    if (!jointValues.length) {
      incompleteRows.push(rowIndex);
    }
  });
  return incompleteRows;
};

export const MAX_CSV_ROWS = 10000;
export const validateNbRows: (
  data: string[][],
  email_address: string,
  phone_number: string,
  location_name: string
) => boolean = (data, email_address, phone_number, location_name) => {
  const cleaned = cleanCSVData(
    data,
    email_address,
    phone_number,
    location_name
  );
  return cleaned.length <= MAX_CSV_ROWS;
};

export const checkMissingLocationRows: (
  data: string[][],
  locationNameCol: string
) => number[] = (data, locationNameCol) => {
  const requiredIndex: number = data[0].indexOf(locationNameCol);
  const missingLocationRows: number[] = [];
  data.forEach((row, rowIndex) => {
    if (!row[requiredIndex]) {
      missingLocationRows.push(rowIndex);
    }
  });
  return missingLocationRows;
};

export const validateEmailAddresses: (
  data: string[][],
  emailColName: string
) => number[] = (data, emailColName) => {
  const emailIndex: number = data[0].indexOf(emailColName);
  const invalidEmailRows: number[] = [];
  data.forEach((row, rowIndex) => {
    // don't validate header row and only validate if there is a value
    if (rowIndex && row[emailIndex] && !validateEmail(row[emailIndex])) {
      invalidEmailRows.push(rowIndex);
    }
  });
  return invalidEmailRows;
};

export const countEmailRows: (
  data: string[][],
  emailColName: string
) => number = (data, emailColName) => {
  const emailIndex: number = data[0].indexOf(emailColName);
  return data.filter((row, rowIndex) => rowIndex && row[emailIndex]).length;
};

export const validateEmail: (email: string) => boolean = (email) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhoneNumbers: (
  data: string[][],
  phoneNumberColName: string
) => number[] = (data, phoneNumberColName) => {
  const phoneNumberIndex: number = data[0].indexOf(phoneNumberColName);
  const invalidPhoneNumberRows: number[] = [];
  data.forEach((row, rowIndex) => {
    // don't validate header row and only validate if there is a value
    if (
      rowIndex &&
      row[phoneNumberIndex] &&
      !validatePhoneNumber(row[phoneNumberIndex])
    ) {
      invalidPhoneNumberRows.push(rowIndex);
    }
  });
  return invalidPhoneNumberRows;
};

export const validatePhoneNumber: (phoneNumber: string) => boolean = (
  phoneNumber
) => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, "FR");
    return (
      phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)
    );
  } catch (e) {
    return false;
  }
};

export const formatPhoneNumber: (phoneNumber: string) => string = (
  phoneNumber
) => {
  const number = phoneUtil.parseAndKeepRawInput(phoneNumber, "FR");
  return phoneUtil.format(number, PhoneNumberFormat.E164);
};
