import React, { useEffect, useRef, useState } from "react";
import { useOutsideClickEvents } from "../../hooks/useOutsideClickEvents";
import { useKeyDownEvents } from "../../hooks/useKeyDownEvents";
import { AnimatePresence, motion } from "framer-motion";

const SIZES = {
  fit: null,
  sm: "338px",
  smmd: "480px",
  md: "628px",
  lg: "900px",
};

interface IModalProps {
  isShowing: boolean; // handle showing state internally for animations to work
  onDismiss: () => void;
  children: React.ReactNode;
  size?: keyof typeof SIZES;
  ignoreOutsideClicks?: boolean;
  doNotCloseClasses?: string[];
}

const Modal = ({
  children,
  isShowing,
  onDismiss,
  size = "sm",
  ignoreOutsideClicks = false,
  doNotCloseClasses = [],
}: IModalProps) => {
  const mdlRf = useRef(null);

  const [showBg, setShowBg] = useState(false);

  useOutsideClickEvents(
    [mdlRf],
    onDismiss,
    [isShowing, onDismiss],
    doNotCloseClasses,
    ignoreOutsideClicks
  );
  useKeyDownEvents([27], onDismiss, [isShowing, onDismiss]);

  useEffect(() => {
    if (isShowing) {
      setShowBg(true);
    }
  }, [isShowing]);

  return (
    <AnimatePresence>
      {showBg && (
        <motion.div
          key="backdrop"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ backgroundColor: "rgba(29, 44, 60, 0.3)" }}
          className="tw-fixed tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-items-center tw-justify-center tw-z-100"
        >
          <AnimatePresence onExitComplete={() => setShowBg(false)}>
            {isShowing && (
              <motion.div
                initial={{ opacity: 0, transform: "translateY(1.5%)" }}
                animate={{ opacity: 1, transform: "translateY(0%)" }}
                exit={{ opacity: 0, transform: "translateY(1.5%)" }}
                className={`tw-w-full tw-overflow-visible`}
                style={
                  SIZES[size]
                    ? { maxWidth: SIZES[size] || SIZES["sm"] }
                    : { width: "fit-content" }
                }
              >
                <div
                  ref={mdlRf}
                  className="tw-shadow-lg tw-bg-white tw-rounded-lg tw-w-full"
                >
                  {children}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
