import { createStandardAction } from "typesafe-actions";
import { APIRequestConfig } from "../../types";
import { INotificationSettings } from "./types";
import { requestActionCreator } from "../../actionCreators";

export const getNotificationSettingsRequest = (id: string) => {
  return requestActionCreator("@notifications/getNotificationSettings")({
    request: {
      url: `/notifications/usage-alerts/${id}`,
      method: "GET",
    },
  });
};

export const getNotificationSettingsPending = createStandardAction(
  "@notifications/getNotificationSettingsPending"
)();
export const getNotificationSettingsSuccess = createStandardAction(
  "@notifications/getNotificationSettingsSuccess"
)<INotificationSettings, { originalRequest: APIRequestConfig }>();
export const getNotificationSettingsError = createStandardAction(
  "@notifications/getNotificationSettingsError"
)();

export const updateNotificationSettingsRequest = (
  id: string,
  payload: Partial<INotificationSettings>
) => {
  return requestActionCreator("@notifications/updateNotificationSettings")({
    request: {
      url: `/notifications/usage-alerts/${id}`,
      method: "PATCH",
      payload,
    },
  });
};

export const updateNotificationSettingsPending = createStandardAction(
  "@notifications/updateNotificationSettingsPending"
)();
export const updateNotificationSettingsSuccess = createStandardAction(
  "@notifications/updateNotificationSettingsSuccess"
)<INotificationSettings, { originalRequest: APIRequestConfig }>();
export const updateNotificationSettingsError = createStandardAction(
  "@notifications/updateNotificationSettingsError"
)();

// Negative
export const getNegativeNotificationSettingsRequest = (id: string) => {
  return requestActionCreator("@notifications/getNegativeNotificationSettings")(
    {
      request: {
        url: `/notifications/negative-responses/${id}`,
        method: "GET",
      },
    }
  );
};

export const getNegativeNotificationSettingsPending = createStandardAction(
  "@notifications/getNegativeNotificationSettingsPending"
)();
export const getNegativeNotificationSettingsSuccess = createStandardAction(
  "@notifications/getNegativeNotificationSettingsSuccess"
)<INotificationSettings, { originalRequest: APIRequestConfig }>();
export const getNegativeNotificationSettingsError = createStandardAction(
  "@notifications/getNegativeNotificationSettingsError"
)();

export const updateNegativeNotificationSettingsRequest = (
  id: string,
  payload: Partial<INotificationSettings>
) => {
  return requestActionCreator(
    "@notifications/updateNegativeNotificationSettings"
  )({
    request: {
      url: `/notifications/negative-responses/${id}`,
      method: "PATCH",
      payload,
    },
  });
};

export const updateNegativeNotificationSettingsPending = createStandardAction(
  "@notifications/updateNegativeNotificationSettingsPending"
)();
export const updateNegativeNotificationSettingsSuccess = createStandardAction(
  "@notifications/updateNegativeNotificationSettingsSuccess"
)<INotificationSettings, { originalRequest: APIRequestConfig }>();
export const updateNegativeNotificationSettingsError = createStandardAction(
  "@notifications/updateNegativeNotificationSettingsError"
)();
