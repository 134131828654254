import { Middleware } from "redux";
import * as locationActions from "../slices/locations/actions";
import * as accountActions from "../slices/account/actions";
import { RootAction } from "../actions";
import { getType } from "typesafe-actions";
import { push } from "connected-react-router";
import { openInNewTab } from "../../functions/openInNewTab";

export const redirectMiddleware: Middleware = (store) => (next) => (
  action: RootAction
) => {
  next(action);

  switch (action.type) {
    case getType(locationActions.archiveLocationSuccess):
      return store.dispatch(push("/locations"));
    case getType(accountActions.getGmbAuthLinkSuccess):
      if (action.payload) {
        openInNewTab(action.payload.authorization_url);
      }
      return;
    default:
      return;
  }
};
