import { createSelector } from "reselect";
import { AppState } from "../../index";
import {
  AccountGetResponse,
  AccountState,
  CompanyAccountPermissions,
  CompanyConfigurations,
  GmbAccount,
} from "./types";
import { Selector } from "react-redux";
import { selectLocations } from "../locations/selectors";
import { selectCustomSurveyById } from "../surveys/selectors";

export const selectAccountState = (state: AppState): AccountState =>
  state.account;

export const selectIsLoggedIn: Selector<AppState, boolean> = createSelector(
  selectAccountState,
  (account) => !!(account.cache && account.cache.token)
);

export const selectJwtToken: Selector<AppState, string> = createSelector(
  selectAccountState,
  (account) => (account.cache && account.cache.token) || ""
);

export const selectIsLoadingCache: Selector<AppState, boolean> = createSelector(
  selectAccountState,
  (account) => account.isLoadingCache
);

export const selectLoginPending: Selector<AppState, boolean> = createSelector(
  selectAccountState,
  (account) => account.ui.loginPending
);

export const selectSignUpPending: Selector<AppState, boolean> = createSelector(
  selectAccountState,
  (account) => account.ui.signUpPending
);

export const selectAccountDataIsLoading: Selector<
  AppState,
  boolean
> = createSelector(selectAccountState, (account) => account.isLoading);

export const selectAccountChangeIsLoading: Selector<
  AppState,
  boolean
> = createSelector(selectAccountState, (account) => account.ui.changePending);

export const selectAccountData: Selector<
  AppState,
  AccountGetResponse
> = createSelector(selectAccountState, (account) => account.data!);

export const selectCompanyId: Selector<
  AppState,
  number
> = createSelector(selectAccountData, (data) => (!data ? 0 : data.company.id));

export const selectCompanyName: Selector<
  AppState,
  string
> = createSelector(selectAccountData, (data) =>
  !data ? "" : data.company.name
);

export const selectGmbData: Selector<
  AppState,
  { isLoading: boolean; authorization_url: string | null }
> = createSelector(selectAccountState, (account) => account.gmb);

export const selectGmbAccount: Selector<
  AppState,
  GmbAccount | null | undefined
> = createSelector(selectAccountState, (account) => account.data?.gmb_account);

export const selectConfigurations: Selector<
  AppState,
  CompanyConfigurations
> = createSelector(
  selectAccountState,
  ({ data }) => data!.company?.configurations
);

export const selectIsBlocked: Selector<AppState, boolean> = createSelector(
  selectConfigurations,
  (data) => data?.is_blocked
);

export const selectLocationLimit: Selector<AppState, number> = createSelector(
  selectConfigurations,
  (data) => data?.num_locations
);

export const selectIsSurveyBuilderEnabled: Selector<
  AppState,
  boolean
> = createSelector(
  selectConfigurations,
  (data) => data?.survey_builder_enabled
);

export const selectIsAiSurveyResponsesEnabled: Selector<
  AppState,
  boolean
> = createSelector(
  selectConfigurations,
  (data) => data?.ai_survey_responses_enabled
);

export const selectIsAutoResponseEnabled: Selector<
  AppState,
  boolean
> = createSelector(
  selectConfigurations,
  (data) => data?.auto_responses_enabled
);

export const selectReferralsEnabled: Selector<
  AppState,
  boolean
> = createSelector(selectConfigurations, (data) => data?.referrals_enabled);

export const selectGmbEnabled: Selector<AppState, boolean> = createSelector(
  selectConfigurations,
  ({ gmb_enabled }) => gmb_enabled
);

export const selectBlockInvalidCSVSize: Selector<
  AppState,
  number
> = createSelector(
  selectConfigurations,
  ({ block_invalid_csv_size }) => block_invalid_csv_size
);

export const selectIsVerified: Selector<AppState, boolean> = createSelector(
  selectAccountData,
  ({ is_verified }) => is_verified
);

export const selectIsOwner: Selector<AppState, boolean> = createSelector(
  selectAccountData,
  (accountData) => accountData?.is_owner
);

export const selectUserPermissions: Selector<
  AppState,
  string[]
> = createSelector(
  selectAccountData,
  (accountData) => accountData?.user_permissions?.user_permissions
);

export const selectUserLocationPermissionsById: (
  id: number
) => Selector<AppState, string[]> = (id) => {
  return createSelector(
    selectAccountData,
    ({ user_permissions }) => user_permissions.location_permissions[id]
  );
};

export const selectUserLocationPermissions: Selector<
  AppState,
  { [key: string]: string[] }
> = createSelector(
  selectAccountData,
  ({ user_permissions }) => user_permissions.location_permissions
);

export const selectLocationsWithUploadCustomerPermissions: Selector<
  AppState,
  number[]
> = createSelector(
  selectAccountData,
  selectLocations,
  (
    {
      user_permissions: { location_permissions: locationPermissions },
      is_owner: isOwner,
    },
    locations
  ) => {
    const liveLocations = locations.filter(({ archived }) => !archived);
    if (!liveLocations.length) {
      return [];
    }

    if (isOwner) {
      return liveLocations.map((loc) => loc.id);
    }

    if (!locationPermissions) {
      return [];
    }

    const permissionedLocations = [];
    for (const [locationId, permissions] of Object.entries(
      locationPermissions
    )) {
      if (
        permissions.includes("add_customer_to_location") &&
        permissions.includes("view_location")
      ) {
        permissionedLocations.push(parseInt(locationId));
      }
    }

    return permissionedLocations;
  }
);

export const selectCanCreateCustomers: Selector<
  AppState,
  boolean
> = createSelector(
  selectLocationsWithUploadCustomerPermissions,
  selectConfigurations,
  (permissionedLocations, companyConfig) => {
    if (companyConfig.sendinblue_enabled && permissionedLocations.length) {
      return true;
    }
    return false;
  }
);

export const selectCanCreateSurveys: Selector<
  AppState,
  boolean
> = createSelector(
  selectAccountData,
  selectLocations,
  (
    {
      user_permissions: { user_permissions: userPermissions },
      is_owner: isOwner,
    },
    locations
  ) => {
    const liveLocations = locations.filter(({ archived }) => !archived);
    return (
      isOwner ||
      (userPermissions &&
        userPermissions.includes("surveys.add_customsurvey") &&
        liveLocations.length > 0)
    );
  }
);

export const selectCanEditSurvey: (
  surveyId: number
) => Selector<AppState, boolean> = (surveyId: number) =>
  createSelector(
    selectAccountData,
    selectCustomSurveyById(surveyId),
    (
      {
        user_permissions: { location_permissions: locationPermissions },
        is_owner: isOwner,
      },
      survey
    ) => {
      if (isOwner) {
        return true;
      }

      if (!survey) {
        return false;
      }

      const surveyLocations = survey.locations;
      for (const locationId of surveyLocations) {
        if (
          !locationPermissions[locationId] ||
          !locationPermissions[locationId].includes("change_location")
        ) {
          return false;
        }
      }
      return true;
    }
  );

export const selectCanCreateLocations: Selector<
  AppState,
  boolean
> = createSelector(selectAccountData, (data) => {
  const userPermissions = data?.user_permissions?.user_permissions;
  const isOwner = data?.is_owner;
  if (!userPermissions) {
    return false;
  }

  return (
    isOwner ||
    (userPermissions && userPermissions.includes("locations.add_location"))
  );
});

export const selectCanEditLocation: (
  locationId: number
) => Selector<AppState, boolean> = (locationId) =>
  createSelector(
    selectAccountData,
    ({
      user_permissions: { location_permissions: locationPermissions },
      is_owner: isOwner,
    }) => {
      if (isOwner) {
        return true;
      }
      return (
        locationPermissions[locationId] &&
        locationPermissions[locationId].includes("change_location")
      );
    }
  );

export const selectCanDeleteLocation: (
  locationId: number
) => Selector<AppState, boolean> = (locationId) =>
  createSelector(
    selectAccountData,
    ({
      user_permissions: { location_permissions: locationPermissions },
      is_owner: isOwner,
    }) => {
      if (isOwner) {
        return true;
      }
      return (
        locationPermissions[locationId] &&
        locationPermissions[locationId].includes("delete_location")
      );
    }
  );

export const selectCanEditReplies: (
  locationId: number
) => Selector<AppState, boolean> = (locationId) =>
  createSelector(
    selectAccountData,
    ({
      user_permissions: { location_permissions: locationPermissions },
      is_owner: isOwner,
    }) => {
      if (isOwner) {
        return true;
      }
      return (
        locationPermissions[locationId] &&
        locationPermissions[locationId].includes("edit_review_comments")
      );
    }
  );

export const selectAllCompanyPermissions: Selector<
  AppState,
  CompanyAccountPermissions
> = createSelector(
  selectAccountState,
  ({ allCompanyPermissions: { data } }) => {
    return data;
  }
);

export const selectCompanyPermissionsPending: Selector<
  AppState,
  boolean
> = createSelector(
  selectAccountState,
  ({
    allCompanyPermissions: {
      ui: { isPending },
    },
  }) => {
    return isPending;
  }
);
