import React from "react";
import ReactTooltip from "react-tooltip";
import { generateId } from "../../functions/generateId";

export type BtnRole = "cta" | "cancel" | "plain" | "delete" | "shadow";

export const styling = {
  cta: "tw-bg-qualiGreen hover:tw-bg-qualiGreenDark tw-text-white",
  cancel: "",
  plain:
    "tw-bg-qualiGrayLight hover:tw-bg-qualiGrayLight tw-text-gray-900 tw-border tw-border-gray-400",
  shadow: "tw-bg-white hover:tw-bg-gray-100 tw-text-gray-900 tw-shadow-md",
  delete:
    "tw-bg-white hover:tw-bg-red-100 tw-text-red-800 tw-border tw-border-red-800",
};

export const disabledStyling = {
  cta: "tw-bg-qualiGreenLight tw-text-white tw-cursor-not-allowed",
  cancel: " tw-cursor-not-allowed",
  plain:
    "tw-bg-gray-200 tw-text-gray-600 tw-border tw-border-gray-400 tw-cursor-not-allowed",
  shadow:
    "tw-bg-white hover:tw-bg-gray-100 tw-text-gray-900 tw-shadow-md tw-cursor-not-allowed",
  delete:
    "tw-bg-red-100 tw-text-red-400 tw-border tw-border-red-400 tw-cursor-not-allowed",
};

export interface Props {
  role?: BtnRole;
  type?: "button" | "submit" | "reset";
  children: React.ReactNode;
  onClick?: () => any;
  block?: boolean;
  isDisabled?: boolean;
  disabledText?: string;
  className?: string;
}

const Btn = ({
  type = "button",
  children,
  onClick,
  role = "cta",
  block,
  isDisabled,
  disabledText,
  className,
}: Props) => {
  const id = generateId();
  return (
    <>
      <button
        data-for={id}
        data-tip={disabledText}
        onClick={() => !isDisabled && onClick && onClick()}
        className={`${
          className || ""
        } tw-px-8 tw-py-4 tw-font-semibold tw-text-sm tw-rounded-lg ${
          isDisabled ? "tw-cursor-not-allowed tw-opacity-50" : ""
        } ${block ? "tw-w-full" : ""}`}
        type={type}
      >
        {children}
      </button>
      {isDisabled && disabledText && (
        <ReactTooltip id={id} className="tw-w-48 tw-text-center" />
      )}
    </>
  );
};

export default Btn;
