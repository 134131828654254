import React from "react";

export type Props = {
  html: string;
};

const RawHtml = ({ html }: Props) => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default RawHtml;
