import { AddCustomerData } from "../store/slices/customers/types";
import { mapCSVData } from "./mapCSVData";
import { formatPhoneNumber, validatePhoneNumber } from "./validateCustomerCSV";
import { deDupeCSVData } from "./deDupeCSVData";

export const cleanCSVData: (
  data: string[][],
  email_address: string,
  phone_number: string,
  location_name: string
) => AddCustomerData[] = (data, email_address, phone_number, location_name) => {
  // map to an array of objects
  const mapped = mapCSVData(data, email_address, phone_number, location_name);
  // tidy up phone numbers *before* deduplication
  const cleaned: AddCustomerData[] = mapped.map(
    ({ email_address, phone_number, location_name }) => ({
      email_address,
      location_name,
      // only attempt to format the phone number if it is valid
      phone_number:
        phone_number && validatePhoneNumber(phone_number)
          ? formatPhoneNumber(phone_number)
          : phone_number,
    })
  );

  // deduplicated based on email addresses and phone numbers
  return deDupeCSVData(cleaned);
};
