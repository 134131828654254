import { IReviewState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";
import { parsePath } from "../../../functions/parsePath";

export type ReviewsAction = ActionType<typeof actions>;

const initialState: IReviewState = {
  data: {
    gmb_reviews: {
      byLocation: {},
    },
    templates: {
      byId: {},
      ids: [],
    },
  },
  ui: {
    gmb_reviews: {
      isLoading: false,
      isSavingName: null,
    },
    templates: {
      isLoading: false,
      hasLoaded: false,
    },
  },
};

const reducer = createReducer(initialState)
  .handleAction(actions.getGmbReviewsPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      gmb_reviews: {
        ...state.ui.gmb_reviews,
        isLoading: true,
      },
    },
  }))
  .handleAction(actions.getGmbReviewsError, (state) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        gmb_reviews: {
          ...state.ui.gmb_reviews,
          isLoading: false,
        },
      },
    };
  })
  .handleAction(
    actions.getGmbReviewsSuccess,
    (
      state,
      {
        payload: {
          averageRating,
          totalReviewCount,
          reviews,
          nextPageToken,
          location_id,
        },
        meta: {
          originalRequest: { url, params },
        },
      }
    ) => {
      const locationId = parseInt(
        parsePath<{ locationId: string }>(url, "/gmb/reviews/:locationId")
          .locationId
      );
      const pageToken = params?.page_token;
      return {
        ...state,
        data: {
          ...state.data,
          gmb_reviews: {
            ...state.data.gmb_reviews,
            byLocation: {
              ...state.data.gmb_reviews.byLocation,
              [locationId]: {
                averageRating,
                totalReviewCount,
                location_id,
                reviews,
                nextPageToken,
              },
            },
          },
        },
        ui: {
          ...state.ui,
          gmb_reviews: {
            ...state.ui.gmb_reviews,
            isLoading: false,
          },
        },
      };
    }
  )
  .handleAction(actions.getAllGmbReviewsPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      gmb_reviews: {
        ...state.ui.gmb_reviews,
        isLoading: true,
      },
    },
  }))
  .handleAction(actions.getAllGmbReviewsError, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      gmb_reviews: {
        ...state.ui.gmb_reviews,
        isLoading: false,
      },
    },
  }))
  .handleAction(
    actions.getAllGmbReviewsSuccess,
    (
      state,
      {
        payload: allReviews,
        meta: {
          originalRequest: { url, params },
        },
      }
    ) => {
      const pageToken = params?.page_token;
      let reviews: any = [];
      for (let key in allReviews) {
        reviews.push(allReviews[key].reviews);
      }

      return {
        ...state,
        data: {
          ...state.data,
          gmb_reviews: {
            ...state.data.gmb_reviews,
            byLocation: {
              ...state.data.gmb_reviews.byLocation,
              ...allReviews,
            },
          },
        },
        ui: {
          ...state.ui,
          gmb_reviews: {
            ...state.ui.gmb_reviews,
            isLoading: false,
          },
        },
      };
    }
  )
  .handleAction(
    actions.updateGmbReviewReplyPending,
    (
      state,
      {
        meta: {
          originalRequest: {
            payload: { name },
          },
        },
      }
    ) => ({
      ...state,
      ui: {
        ...state.ui,
        gmb_reviews: {
          ...state.ui.gmb_reviews,
          isSavingName: name,
        },
      },
    })
  )
  .handleAction(actions.updateGmbReviewReplyError, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      gmb_reviews: {
        ...state.ui.gmb_reviews,
        isSavingName: null,
      },
    },
  }))
  .handleAction(
    actions.deleteGmbReviewReplyPending,
    (
      state,
      {
        meta: {
          originalRequest: {
            payload: { name },
          },
        },
      }
    ) => ({
      ...state,
      ui: {
        ...state.ui,
        gmb_reviews: {
          ...state.ui.gmb_reviews,
          isSavingName: name,
        },
      },
    })
  )
  .handleAction(
    actions.updateGmbReviewReplySuccess,
    (
      state,
      {
        payload,
        meta: {
          originalRequest: {
            payload: { gmbLocationId, name },
          },
        },
      }
    ) => {
      const locationReviews = state.data.gmb_reviews.byLocation[gmbLocationId];
      const reviewToUpdate = locationReviews.reviews.find(
        (rev) => rev.name === name
      );

      if (reviewToUpdate) {
        reviewToUpdate.reviewReply = payload;
      }

      locationReviews.reviews = locationReviews.reviews.map((rev) => ({
        ...rev,
      }));

      return {
        ...state,
        data: {
          ...state.data,
          gmb_reviews: {
            ...state.data.gmb_reviews,
            byLocation: {
              ...state.data.gmb_reviews.byLocation,
              [gmbLocationId]: locationReviews,
            },
          },
        },
        ui: {
          ...state.ui,
          gmb_reviews: {
            ...state.ui.gmb_reviews,
            isSavingName: null,
          },
        },
      };
    }
  )
  .handleAction(
    actions.deleteGmbReviewReplySuccess,
    (
      state,
      {
        meta: {
          originalRequest: {
            payload: { name },
          },
        },
      }
    ) => ({
      ...state,
      data: {
        ...state.data,
        gmb_reviews: {
          ...state.data.gmb_reviews,
        },
      },
      ui: {
        ...state.ui,
        gmb_reviews: {
          ...state.ui.gmb_reviews,
          isSavingName: null,
        },
      },
    })
  )
  .handleAction(actions.getReviewTemplatesPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      templates: {
        ...state.ui.templates,
        isLoading: true,
      },
    },
  }))
  .handleAction(
    actions.getReviewTemplatesSuccess,
    (state, { payload: { results } }) => ({
      ...state,
      data: {
        ...state.data,
        templates: {
          byId: results.reduce(
            (prev, next) => ({
              ...prev,
              [next.id]: {
                ...next,
              },
            }),
            {}
          ),
          ids: results.map(({ id }) => id),
        },
      },
      ui: {
        ...state.ui,
        templates: {
          ...state.ui.templates,
          isLoading: false,
          hasLoaded: true,
        },
      },
    })
  )
  .handleAction(actions.createReviewTemplatePending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      templates: {
        ...state.ui.templates,
        isLoading: true,
      },
    },
  }))
  .handleAction(
    actions.createReviewTemplateSuccess,
    (state, { payload: result }) => ({
      ...state,
      data: {
        ...state.data,
        templates: {
          byId: {
            ...state.data.templates.byId,
            [result.id]: result,
          },
          ids: [...state.data.templates.ids, result.id],
        },
      },
      ui: {
        ...state.ui,
        templates: {
          ...state.ui.templates,
          isLoading: false,
          hasLoaded: true,
        },
      },
    })
  )
  .handleAction(actions.updateReviewTemplatePending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      templates: {
        ...state.ui.templates,
        isLoading: true,
      },
    },
  }))
  .handleAction(
    actions.updateReviewTemplateSuccess,
    (state, { payload: result }) => ({
      ...state,
      data: {
        ...state.data,
        templates: {
          ...state.data.templates,
          byId: {
            ...state.data.templates.byId,
            [result.id]: result,
          },
        },
      },
      ui: {
        ...state.ui,
        templates: {
          ...state.ui.templates,
          isLoading: false,
          hasLoaded: true,
        },
      },
    })
  )
  .handleAction(actions.deleteReviewTemplatePending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      templates: {
        ...state.ui.templates,
        isLoading: true,
      },
    },
  }))
  .handleAction(
    actions.deleteReviewTemplateSuccess,
    (
      state,
      {
        payload: result,
        meta: {
          originalRequest: { url, params },
        },
      }
    ) => {
      const id = parseInt(
        parsePath<{ id: string }>(url, "/review-templates/:id").id
      );
      const byIdArr = state.data.templates.byId;
      delete byIdArr[id];
      return {
        ...state,
        data: {
          ...state.data,
          templates: {
            ...state.data.templates,
            byId: {
              ...byIdArr,
            },
            ids: [...state.data.templates.ids.filter((item) => item !== id)],
          },
        },
        ui: {
          ...state.ui,
          templates: {
            ...state.ui.templates,
            isLoading: false,
            hasLoaded: true,
          },
        },
      };
    }
  );

export { reducer as reviewsReducer };
