import React from "react";

const Page404 = () => {
  return (
    <div className="tw-p-6">
      <h2>404 - page not found</h2>
    </div>
  );
};

export default Page404;
