import React from "react";
import { useTranslation } from "react-i18next";

const stringToOneDecimalPlace = (val: string) => {
  return Math.min(parseFloat(parseFloat(val).toFixed(1)), 5);
};
export default function TargetGradeInput({
  value,
  onChange,
}: {
  value: number | null;
  onChange: (val: number) => void;
}) {
  const { t } = useTranslation();
  return (
    <input
      value={value || ""}
      onChange={(e) => onChange(stringToOneDecimalPlace(e.currentTarget.value))}
      placeholder={t("components.targetGradeInput.targetGrade")}
      type="number"
      step={0.1}
      max={"5"}
      className="tw-text-medium tw-px-4 tw-py-2 tw-rounded-xl tw-custom-shadow tw-w-full tw-border tw-border-divider tw-mr-4 tw-mb-2"
    />
  );
}
