import { DomainConfig, WhiteLabellingState } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";
import { parseDomainName } from "../../../utilities/domainNames";

export type WhiteLabellingAction = ActionType<typeof actions>;

const initialState: WhiteLabellingState = {
  data: {
    domainConfigs: {
      byDomain: {},
    },
    gotDomainConfig: false,
  },
};

const reducer = createReducer(initialState).handleAction(
  actions.getDomainConfigsSuccess,
  (state, { payload }: { payload: DomainConfig[] }) => {
    if (payload) {
      const domainConfigsByDomain = {} as { [domain: string]: DomainConfig };
      for (const domainConfig of payload) {
        domainConfigsByDomain[
          parseDomainName(domainConfig.domain_name)
        ] = domainConfig;
      }
      return {
        ...state,
        data: {
          domainConfigs: {
            byDomain: domainConfigsByDomain,
          },
          gotDomainConfig: true,
        },
      };
    }
    return state;
  }
);

export { reducer as whiteLabellingReducer };
