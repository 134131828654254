import { AnyAction, Middleware, MiddlewareAPI } from "redux";
import { getAccountRequest, notAuthorised } from "../slices/account/actions";

export const apiMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);

  if (!action.meta || !action.meta.request) {
    return;
  }

  if (process.env.REACT_APP_MOCK_API === "true" && action.meta.request.mock) {
    const { status, body, delay = 1000 } = action.meta.request.mock;
    console.warn(`mocking request to ${action.meta.request.url}`);
    handlePending(action.type, store);
    setTimeout(() => {
      handleResponse(status, action, body, store);
    }, delay);
    return;
  }

  let {
    url,
    method = "GET",
    authenticated = true,
    payload,
  } = action.meta.request;

  const init: any = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (authenticated) {
    const token = store.getState().account.cache.token;
    init.headers["Authorization"] = `Bearer ${token}`;
  }

  if (payload && payload instanceof FormData) {
    init.body = payload;
    delete init.headers["Content-Type"];
  } else if (payload) {
    init.body = JSON.stringify(payload);
  }

  // Django can't redirect to the slash URL while maintaining POST data
  url = `${url}/`;

  if (action.meta.request.params) {
    let query = Object.keys(action.meta.request.params)
      .map(
        (k) =>
          encodeURIComponent(k) +
          "=" +
          encodeURIComponent(action.meta.request.params[k])
      )
      .join("&");

    url = `${url}?${query}`;
  }

  handlePending(action, store);

  fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, init)
    .then((response: Response) => {
      if (response.status === 204) {
        // empty responses (e.g. from a DELETE) can't be converted to json
        return {
          status: response.status,
          json: {},
        };
      } else {
        return response
          .json()
          .then((json) => ({
            status: response.status,
            json,
          }))
          .catch((err) => {
            console.error(err);
            return {
              status: response.status,
              json: {},
            };
          });
      }
    })
    .then(({ status, json }) => {
      handleResponse(status, action, json, store);
    });
};

const handlePending = (
  { type, meta: { request: originalRequest } }: AnyAction,
  store: MiddlewareAPI
) => {
  store.dispatch({ type: `${type}Pending`, meta: { originalRequest } });
};

const handleResponse = (
  status: number,
  { type, meta: { request: originalRequest } }: AnyAction,
  body: any,
  store: MiddlewareAPI
) => {
  if (status < 400) {
    store.dispatch({
      type: `${type}Success`,
      payload: body,
      meta: { originalRequest },
    });
    if (type === "@locations/createLocation") {
      store.dispatch(getAccountRequest());
    }
  } else if (status === 401 && type !== "@account/login") {
    // toast.error(i18n.t("notifications.401.message"));
    store.dispatch(notAuthorised());
  } else {
    store.dispatch({
      type: `${type}Error`,
      payload: body,
    });
  }
};
