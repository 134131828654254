import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { localStorageMiddleware } from "./middleware/localStorage";
import { accountReducer } from "./slices/account/reducer";
import { AccountState } from "./slices/account/types";
import { apiMiddleware } from "./middleware/api";
import { customerReducer } from "./slices/customers/reducer";
import { CustomersState } from "./slices/customers/types";
import { notificationsMiddleware } from "./middleware/notifications";
import { chunkedUploadMiddleware } from "./middleware/chunkedUpload";
import { locationsReducer } from "./slices/locations/reducer";
import { ILocationState } from "./slices/locations/types";
import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from "connected-react-router";
import { createBrowserHistory } from "history";
import { IReviewState } from "./slices/reviews/types";
import { reviewsReducer } from "./slices/reviews/reducer";
import { AppPagesState } from "./slices/app-pages/types";
import { appPagesReducer } from "./slices/app-pages/reducer";
import { redirectMiddleware } from "./middleware/redirects";
import { INotificationsState } from "./slices/notifications/types";
import { notificationsReducer } from "./slices/notifications/reducer";
import { IStatisticsState } from "./slices/statistics/types";
import { statisticsReducer } from "./slices/statistics/reducer";
import { ISurveyState } from "./slices/surveys/types";
import { surveysReducer } from "./slices/surveys/reducer";
import { IJobTypeState } from "./slices/job-types/types";
import { jobTypesReducer } from "./slices/job-types/reducer";
import { WhiteLabellingState } from "./slices/whitelabelling/types";
import { whiteLabellingReducer } from "./slices/whitelabelling/reducer";
import { IReferralsState } from "./slices/referrals/types";
import { referralsReducer } from "./slices/referrals/reducer";
import { SendinblueState } from "./slices/sendinblue/types";
import { sendinblueReducer } from "./slices/sendinblue/reducer";

export const history = createBrowserHistory();

export interface AppState {
  account: AccountState;
  customers: CustomersState;
  locations: ILocationState;
  statistics: IStatisticsState;
  surveys: ISurveyState;
  router: RouterState;
  reviews: IReviewState;
  appPages: AppPagesState;
  notifications: INotificationsState;
  jobTypes: IJobTypeState;
  whiteLabelling: WhiteLabellingState;
  sendinblue: SendinblueState;
  referrals: IReferralsState;
}

export const configureStore = () => {
  return createStore(
    combineReducers({
      router: connectRouter(history),
      account: accountReducer,
      customers: customerReducer,
      locations: locationsReducer,
      statistics: statisticsReducer,
      surveys: surveysReducer,
      reviews: reviewsReducer,
      appPages: appPagesReducer,
      notifications: notificationsReducer,
      jobTypes: jobTypesReducer,
      whiteLabelling: whiteLabellingReducer,
      sendinblue: sendinblueReducer,
      referrals: referralsReducer,
    }),
    {} as any,
    composeWithDevTools(
      applyMiddleware(
        apiMiddleware,
        localStorageMiddleware,
        notificationsMiddleware,
        chunkedUploadMiddleware,
        redirectMiddleware,
        routerMiddleware(history)
      )
    )
  );
};
